import { GetHeartbeatChartMainDataQueryResult } from '@tapestry/shared/graphql';
import { MeasureSlug } from '@tapestry/types';
import { makeMockDataValues } from '../utils/mock-chart-value';

interface IHeartbeatChartParams {
  startDate: string;
  endDate: string;
  startDateCompare: string;
  endDateCompare: string;
}

interface IHeartbeatChartResponse {
  [MeasureSlug.TotalSales]: Partial<GetHeartbeatChartMainDataQueryResult>;
  [MeasureSlug.Profitability]: Partial<GetHeartbeatChartMainDataQueryResult>;
  [MeasureSlug.CaptureRate]: Partial<GetHeartbeatChartMainDataQueryResult>;
  [MeasureSlug.ReturningVisitor]: Partial<GetHeartbeatChartMainDataQueryResult>;
  [MeasureSlug.Transactions]: Partial<GetHeartbeatChartMainDataQueryResult>;
  [MeasureSlug.BasketValue]: Partial<GetHeartbeatChartMainDataQueryResult>;
  [MeasureSlug.BasketProfit]: Partial<GetHeartbeatChartMainDataQueryResult>;
  [MeasureSlug.Margin]: Partial<GetHeartbeatChartMainDataQueryResult>;
  [MeasureSlug.PromotionalMixOfSales]: Partial<GetHeartbeatChartMainDataQueryResult>;
  [MeasureSlug.SalesPerLabourHour]: Partial<GetHeartbeatChartMainDataQueryResult>;
  [MeasureSlug.TotalWageCost]: Partial<GetHeartbeatChartMainDataQueryResult>;
  [MeasureSlug.TotalWageHours]: Partial<GetHeartbeatChartMainDataQueryResult>;
}

export const getHeartbeatBarChartDataChart = ({
  startDate,
  endDate,
}: IHeartbeatChartParams): IHeartbeatChartResponse => ({
  [MeasureSlug.TotalSales]: {
    data: {
      heartbeatChartMainCompareV2: {
        data: makeMockDataValues(startDate, endDate, () =>
          Number(Math.max(6200, Math.random() * 12000).toFixed(2))
        ),
        __typename: 'heartbeatChartMainCompareType',
      },
    },
  },
  [MeasureSlug.Profitability]: {
    data: {
      heartbeatChartMainCompareV2: {
        data: makeMockDataValues(startDate, endDate, () =>
          Number(Math.max(6200, Math.random() * 12000).toFixed(2))
        ),
        __typename: 'heartbeatChartMainCompareType',
      },
    },
  },
  [MeasureSlug.CaptureRate]: {
    data: {
      heartbeatChartMainCompareV2: {
        data: makeMockDataValues(startDate, endDate, () =>
          Number(Math.max(15, Math.random() * 50).toFixed(2))
        ),
        __typename: 'heartbeatChartMainCompareType',
      },
    },
  },
  [MeasureSlug.ReturningVisitor]: {
    data: {
      heartbeatChartMainCompareV2: {
        data: makeMockDataValues(startDate, endDate, () =>
          Number(Math.max(15, Math.random() * 60).toFixed(2))
        ),
        __typename: 'heartbeatChartMainCompareType',
      },
    },
  },
  [MeasureSlug.Transactions]: {
    data: {
      heartbeatChartMainCompareV2: {
        data: makeMockDataValues(startDate, endDate, () =>
          Number(Math.max(15, Math.random() * 200).toFixed())
        ),
        __typename: 'heartbeatChartMainCompareType',
      },
    },
  },
  [MeasureSlug.BasketValue]: {
    data: {
      heartbeatChartMainCompareV2: {
        data: makeMockDataValues(startDate, endDate, () =>
          Number(Math.max(30, Math.random() * 36).toFixed(2))
        ),
        __typename: 'heartbeatChartMainCompareType',
      },
    },
  },
  [MeasureSlug.BasketProfit]: {
    data: {
      heartbeatChartMainCompareV2: {
        data: makeMockDataValues(startDate, endDate, () =>
          Number(Math.max(7, Math.random() * 12).toFixed(2))
        ),
        __typename: 'heartbeatChartMainCompareType',
      },
    },
  },
  [MeasureSlug.Margin]: {
    data: {
      heartbeatChartMainCompareV2: {
        data: makeMockDataValues(startDate, endDate, () =>
          Number(Math.max(20, Math.random() * 23).toFixed(2))
        ),
        __typename: 'heartbeatChartMainCompareType',
      },
    },
  },
  [MeasureSlug.PromotionalMixOfSales]: {
    data: {
      heartbeatChartMainCompareV2: {
        data: makeMockDataValues(startDate, endDate, () =>
          Number(Math.max(50, Math.random() * 58).toFixed(2))
        ),
        __typename: 'heartbeatChartMainCompareType',
      },
    },
  },
  [MeasureSlug.SalesPerLabourHour]: {
    data: {
      heartbeatChartMainCompareV2: {
        data: makeMockDataValues(startDate, endDate, () =>
          Number(Math.max(69, Math.random() * 175).toFixed(2))
        ),
        __typename: 'heartbeatChartMainCompareType',
      },
    },
  },
  [MeasureSlug.TotalWageCost]: {
    data: {
      heartbeatChartMainCompareV2: {
        data: makeMockDataValues(startDate, endDate, () =>
          Number(Math.max(10989, Math.random() * 23463).toFixed(2))
        ),
        __typename: 'heartbeatChartMainCompareType',
      },
    },
  },
  [MeasureSlug.TotalWageHours]: {
    data: {
      heartbeatChartMainCompareV2: {
        data: makeMockDataValues(startDate, endDate, () =>
          Number(Math.max(333, Math.random() * 711).toFixed(2))
        ),
        __typename: 'heartbeatChartMainCompareType',
      },
    },
  },
});
