const MELBOURNE_POSTCODES_ARRAY = [
  8399, 8002, 3003, 8008, 3004, 8005, 3006, 3006, 8012, 3010, 3011, 3011, 3011,
  3012, 3012, 3012, 3012, 3012, 3013, 3013, 3015, 3015, 3015, 3016, 3016, 3018,
  3018, 3019, 3019, 3020, 3020, 3020, 3020, 3020, 3021, 3021, 3021, 3021, 3022,
  3022, 3023, 3023, 3023, 3023, 3023, 3023, 3023, 3024, 3024, 3024, 3025, 3025,
  3025, 3026, 3027, 3028, 3028, 3028, 3029, 3029, 3029, 3030, 3030, 3030, 3030,
  3030, 3030, 3031, 3031, 3032, 3032, 3032, 3032, 3033, 3034, 3036, 3036, 3875,
  3037, 3037, 3037, 3037, 3038, 3038, 3038, 3038, 3039, 3040, 3040, 3040, 3041,
  3041, 3041, 3041, 3041, 3042, 3042, 3042, 3042, 3043, 3043, 3043, 3044, 3044,
  3045, 3046, 3046, 3046, 3047, 3047, 3047, 3048, 3048, 3049, 3049, 3050, 3051,
  3051, 3052, 3052, 3053, 3053, 3054, 3054, 3055, 3055, 3055, 3055, 3056, 3056,
  3056, 3057, 3057, 3058, 3058, 3058, 3058, 3058, 3059, 3060, 3061, 3062, 3063,
  3063, 3064, 3064, 3064, 3064, 3064, 3065, 3066, 3066, 3067, 3068, 3068, 3070,
  3070, 3071, 3072, 3072, 3072, 3072, 3072, 3072, 3072, 3073, 3073, 3074, 3075,
  3076, 3076, 3078, 3078, 3079, 3079, 3079, 3081, 3081, 3381, 3081, 3082, 3083,
  3083, 3086, 3084, 3084, 3084, 3084, 3084, 3085, 3085, 3085, 3087, 3087, 3088,
  3088, 3088, 3088, 3089, 3090, 3091, 3093, 3094, 3095, 3095, 3095, 3096, 3097,
  3097, 3097, 3099, 3099, 3099, 3099, 3099, 3101, 3101, 3102, 3103, 3103, 3103,
  3103, 3104, 3104, 3105, 3106, 3107, 3108, 3109, 3109, 3109, 3110, 3111, 3113,
  3113, 3114, 3115, 3116, 3121, 3121, 3121, 3121, 3121, 3121, 3121, 3122, 3122,
  3122, 3122, 3122, 3123, 3123, 3124, 8627, 3124, 3124, 3124, 3124, 3125, 3125,
  3125, 3126, 3126, 3127, 3127, 3127, 3128, 3128, 3128, 3128, 3128, 3129, 3129,
  3129, 3130, 3130, 3130, 3130, 3131, 3131, 3131, 3132, 3132, 3132, 3133, 3133,
  3134, 3134, 3134, 3134, 3134, 3135, 3135, 3135, 3136, 3136, 3136, 3136, 3137,
  3137, 3138, 3139, 3139, 3139, 3139, 3139, 3139, 3139, 3139, 3139, 3139, 3140,
  3141, 3142, 3142, 3143, 3143, 3144, 3144, 3144, 3145, 3145, 3145, 3145, 3145,
  3145, 3146, 3146, 3147, 3147, 3148, 3148, 3148, 3148, 3149, 3149, 3149, 3150,
  3150, 3150, 3151, 3151, 3152, 3152, 3152, 3152, 3153, 3153, 3154, 3155, 3156,
  3156, 3156, 3156, 3156, 3158, 3159, 3159, 3160, 3160, 3160, 3160, 3161, 3161,
  3162, 3162, 3162, 3163, 3163, 3163, 3163, 3175, 3165, 3165, 3166, 3166, 3166,
  3166, 3167, 3168, 3168, 3169, 3169, 3170, 3170, 3171, 3171, 3172, 3172, 3173,
  3174, 3174, 3175, 3175, 3175, 3175, 3175, 3176, 3177, 3177, 3178, 3179, 3180,
  3181, 3181, 3181, 3182, 3182, 3182, 3183, 3183, 3184, 3184, 3185, 3185, 3185,
  3186, 3186, 3186, 3186, 3187, 3187, 3188, 3188, 3188, 3189, 3189, 3189, 3190,
  3191, 3192, 3192, 3192, 3193, 3193, 3193, 3193, 3194, 3194, 3194, 3195, 3195,
  3195, 3195, 3195, 3195, 3196, 3196, 3196, 3196, 3197, 3197, 3198, 3198, 3199,
  3199, 3199, 3199, 3199, 3199, 3200, 3200, 3201, 3202, 3204, 3204, 3204, 3204,
  3205, 3205, 3206, 3206, 3207, 3207, 3211, 3212, 3212, 3212, 3221, 3221, 3221,
  3221, 3214, 3214, 3214, 3215, 3215, 3215, 3215, 3215, 3215, 3215, 3216, 3216,
  3217, 3216, 3217, 3216, 3216, 3216, 3216, 3217, 3217, 3217, 3221, 3221, 3218,
  3218, 3218, 3221, 3825, 3219, 3219, 3219, 3219, 3219, 3220, 3220, 3351, 3220,
  3221, 3224, 3223, 3221, 3221, 3340, 3234, 3221, 3234, 3234, 3222, 3222, 3222,
  3222, 3222, 3222, 3223, 3223, 3223, 3224, 3225, 3225, 3225, 3225, 3226, 3227,
  3227, 3227, 3228, 3228, 3228, 3228, 3230, 3231, 3231, 3231, 3555, 3231, 3232,
  3233, 3233, 3233, 3233, 3233, 3233, 3234, 3234, 3234, 3235, 3235, 3235, 3235,
  3236, 3236, 3237, 3237, 3237, 3237, 3237, 3237, 3237, 3237, 3238, 3238, 3238,
  3238, 3239, 3239, 3239, 3239, 3240, 3240, 3240, 3240, 3240, 3240, 3241, 3241,
  3241, 3241, 3241, 3241, 3242, 3243, 3243, 3249, 3243, 3243, 3249, 3249, 3249,
  3249, 3249, 3249, 3249, 3249, 3249, 3249, 3249, 3249, 3249, 3249, 3249, 3249,
  3249, 3249, 3249, 3249, 3249, 3249, 3249, 3249, 3250, 3250, 3250, 3250, 3251,
  3251, 3251, 3251, 3251, 3254, 3260, 3260, 3260, 3260, 3260, 3260, 3260, 3260,
  3260, 3260, 3260, 3260, 3260, 3260, 3260, 3260, 3260, 3260, 3264, 3265, 3265,
  3265, 3265, 3265, 3265, 3265, 3265, 3265, 3265, 3265, 3265, 3265, 3265, 3265,
  3265, 3265, 3266, 3266, 3266, 3266, 3266, 3266, 3266, 3266, 3266, 3266, 3267,
  3268, 3268, 3268, 3268, 3268, 3268, 3268, 3268, 3268, 3268, 3268, 3268, 3268,
  3268, 3268, 3268, 3268, 3268, 3269, 3269, 3269, 3270, 3271, 3271, 3271, 3272,
  3272, 3273, 3274, 3275, 3275, 3276, 3276, 3277, 3277, 3277, 3277, 3277, 3277,
  3278, 3278, 3279, 3279, 3280, 3280, 3281, 3281, 3281, 3281, 3282, 3282, 3283,
  3283, 3283, 3283, 3283, 3283, 3283, 3283, 3283, 3283, 3284, 3284, 3285, 3285,
  3285, 3285, 3285, 3285, 3285, 3285, 3286, 3286, 3286, 3286, 3287, 3287, 3289,
  3289, 3289, 3289, 3289, 3292, 3293, 3293, 3293, 3294, 3294, 3294, 3294, 3294,
  3294, 3294, 3300, 3300, 3301, 3301, 3301, 3301, 3301, 3301, 3301, 3301, 3301,
  3301, 3301, 3301, 3301, 3301, 3301, 3302, 3302, 3303, 3303, 3303, 3303, 3303,
  3304, 3304, 3304, 3304, 3304, 3304, 3304, 3304, 3304, 3304, 3304, 3304, 3305,
  3305, 3305, 3305, 3305, 3305, 3305, 3305, 3305, 3305, 3305, 3305, 3309, 3310,
  3310, 3311, 3311, 3312, 3312, 3312, 3312, 3312, 3312, 3312, 3312, 3312, 3312,
  3312, 3691, 3312, 3312, 3312, 3312, 3312, 3312, 3312, 3314, 3314, 3314, 3314,
  3314, 3315, 3315, 3315, 3315, 3315, 3315, 3315, 3315, 3315, 3315, 3315, 3315,
  3315, 3315, 3315, 3315, 3315, 3317, 3318, 3318, 3318, 3318, 3318, 3318, 3318,
  3319, 3319, 3319, 3321, 3321, 3321, 3322, 3323, 3323, 3323, 3324, 3324, 3324,
  3325, 3325, 3325, 3325, 3328, 3329, 3329, 3329, 3330, 3331, 3331, 3331, 3331,
  3331, 3331, 3331, 3332, 3333, 3333, 3334, 3334, 3334, 3334, 3334, 3335, 3335,
  3337, 3337, 3337, 3337, 3338, 3338, 3338, 3338, 3340, 3340, 3340, 3340, 3340,
  3340, 3340, 3340, 3340, 3340, 3340, 3340, 3341, 3341, 3341, 3341, 3341, 3342,
  3342, 3342, 3342, 3342, 3342, 3342, 3342, 3342, 3345, 3350, 3350, 3353, 3350,
  3354, 3350, 3350, 3465, 3350, 3350, 3350, 3350, 3350, 3350, 3350, 3350, 3350,
  3350, 3350, 3350, 3350, 3350, 3350, 3351, 3351, 3351, 3351, 3351, 3351, 3351,
  3351, 3351, 3351, 3351, 3351, 3351, 3351, 3351, 3351, 3351, 3351, 3351, 3351,
  3351, 3351, 3351, 3351, 3351, 3351, 3352, 3352, 3352, 3352, 3352, 3352, 3352,
  3352, 3352, 3352, 3352, 3352, 3363, 3352, 3352, 3352, 3352, 3352, 3352, 3352,
  3352, 3352, 3352, 3352, 3352, 3352, 3352, 3352, 3364, 3352, 3352, 3352, 3352,
  3352, 3352, 3352, 3352, 3352, 3352, 3352, 3352, 3352, 3352, 3352, 3352, 3352,
  3352, 3352, 3352, 3352, 3352, 3352, 3352, 3355, 3352, 3352, 3352, 3352, 3352,
  3352, 3352, 3354, 3355, 3355, 3355, 3356, 3356, 3357, 3549, 3360, 3360, 3360,
  3360, 3361, 3361, 3361, 3363, 3363, 3363, 3363, 3363, 3363, 3364, 3364, 3364,
  3551, 3364, 3364, 3364, 3364, 3364, 3364, 3364, 3364, 3364, 3370, 3364, 3364,
  3364, 3364, 3364, 3364, 3364, 3364, 3364, 3370, 3370, 3370, 3371, 3371, 3371,
  3371, 3371, 3371, 3371, 3371, 3957, 3371, 3373, 3373, 3373, 3373, 3373, 3373,
  3373, 3373, 3373, 3373, 3373, 3373, 3373, 3377, 3375, 3375, 3375, 3375, 3377,
  3377, 3377, 3377, 3377, 3377, 3377, 3377, 3377, 3377, 3377, 3377, 3377, 3377,
  3377, 3377, 3377, 3377, 3377, 3377, 3378, 3378, 3379, 3379, 3379, 3379, 3379,
  3379, 3379, 3380, 3380, 3381, 3385, 3381, 3381, 3381, 3381, 3381, 3381, 3381,
  3387, 3381, 3384, 3381, 3384, 3384, 3384, 3384, 3384, 3384, 3384, 3384, 3384,
  3385, 3385, 3385, 3385, 3385, 3385, 3385, 3387, 3387, 3387, 3387, 3387, 3387,
  3387, 3387, 3387, 3387, 3388, 3388, 3390, 3390, 3391, 3392, 3392, 3392, 3393,
  3393, 3393, 3393, 3393, 3393, 3393, 3393, 3393, 3395, 3395, 3395, 3395, 3396,
  3402, 3401, 3401, 3401, 3401, 3401, 3401, 3401, 3401, 3401, 3401, 3401, 3401,
  3401, 3401, 3401, 3401, 3401, 3401, 3401, 3401, 3401, 3401, 3401, 3401, 3401,
  3401, 3401, 3401, 3401, 3401, 3401, 3401, 3401, 3407, 3407, 3407, 3407, 3407,
  3409, 3409, 3409, 3409, 3409, 3409, 3409, 3409, 3409, 3409, 3409, 3409, 3412,
  3413, 3413, 3413, 3413, 3414, 3414, 3414, 3415, 3418, 3418, 3418, 3418, 3418,
  3418, 3418, 3418, 3418, 3418, 3419, 3420, 3420, 3420, 3423, 3424, 3424, 3424,
  3427, 3428, 3429, 3429, 3430, 3431, 3432, 3433, 3434, 3434, 3544, 3434, 3435,
  3435, 3435, 3435, 3437, 3437, 3437, 3438, 3440, 3441, 3442, 3442, 3442, 3442,
  3442, 3442, 3442, 3442, 3442, 3444, 3444, 3444, 3444, 3444, 3444, 3444, 3444,
  3444, 3444, 3444, 3444, 3444, 3444, 3444, 3444, 3444, 3444, 3444, 3444, 3444,
  3446, 3446, 3447, 3448, 3448, 3448, 3465, 3450, 3451, 3451, 3451, 3451, 3451,
  3451, 3451, 3451, 3451, 3451, 3451, 3451, 3451, 3451, 3451, 3453, 3453, 3453,
  3453, 3458, 3458, 3458, 3458, 3458, 3458, 3458, 3458, 3458, 3460, 3460, 3461,
  3461, 3461, 3461, 3461, 3461, 3461, 3461, 3461, 3461, 3461, 3461, 3461, 3461,
  3461, 3461, 3461, 3461, 3461, 3461, 3461, 3461, 3461, 3461, 3461, 3461, 3461,
  3461, 3462, 3462, 3462, 3462, 3462, 3463, 3463, 3463, 3463, 3463, 3463, 3463,
  3463, 3463, 3463, 3463, 3515, 3464, 3465, 3465, 3465, 3465, 3465, 3465, 3465,
  3465, 3465, 3465, 3465, 3465, 3465, 3465, 3465, 3465, 3465, 3465, 3465, 3465,
  3467, 3468, 3468, 3469, 3469, 3469, 3469, 3469, 3472, 3472, 3472, 3472, 3472,
  3472, 3472, 3472, 3472, 3472, 3472, 3475, 3475, 3475, 3475, 3475, 3475, 3475,
  3477, 3477, 3478, 3477, 3477, 3477, 3477, 3478, 3477, 3477, 3477, 3477, 3477,
  3477, 3477, 3477, 3477, 3477, 3477, 3477, 3477, 3477, 3477, 3477, 3477, 3477,
  3477, 3477, 3477, 3477, 3478, 3478, 3478, 3478, 3478, 3478, 3478, 3478, 3480,
  3480, 3480, 3480, 3480, 3480, 3480, 3480, 3480, 3480, 3480, 3480, 3480, 3480,
  3480, 3480, 3480, 3480, 3482, 3482, 3482, 3482, 3482, 3483, 3483, 3483, 3483,
  3483, 3483, 3483, 3483, 3483, 3483, 3485, 3485, 3485, 3485, 3487, 3488, 3488,
  3488, 3489, 3490, 3490, 3490, 3490, 3490, 3490, 3490, 3490, 3491, 3494, 3494,
  3494, 3494, 3496, 3496, 3496, 3496, 3496, 3496, 3496, 3496, 3496, 3498, 3500,
  3502, 3501, 3501, 3501, 3501, 3501, 3505, 3505, 3505, 3505, 3505, 3505, 3505,
  3506, 3507, 3509, 3509, 3512, 3512, 3512, 3515, 3515, 3516, 3516, 3516, 3516,
  3516, 3516, 3517, 3517, 3517, 3517, 3517, 3517, 3517, 3517, 3517, 3517, 3517,
  3518, 3518, 3518, 3518, 3518, 3518, 3518, 3518, 3518, 3518, 3518, 3518, 3518,
  3518, 3518, 3518, 3520, 3520, 3521, 3522, 3522, 3523, 3523, 3523, 3523, 3523,
  3523, 3523, 3523, 3523, 3523, 3525, 3525, 3525, 3525, 3525, 3525, 3525, 3525,
  3525, 3525, 3525, 3525, 3525, 3527, 3527, 3527, 3527, 3527, 3527, 3527, 3527,
  3527, 3527, 3527, 3527, 3527, 3529, 3529, 3530, 3530, 3530, 3530, 3531, 3531,
  3533, 3533, 3533, 3533, 3533, 3533, 3533, 3533, 3533, 3533, 3533, 3579, 3537,
  3537, 3537, 3537, 3537, 3537, 3537, 3537, 3537, 3540, 3540, 3540, 3542, 3542,
  3542, 3544, 3544, 3544, 3544, 3544, 3544, 3546, 3546, 3546, 3546, 3546, 3546,
  3546, 3549, 3549, 3549, 3549, 3549, 3549, 3549, 3549, 3549, 3549, 3549, 3550,
  3550, 3550, 3550, 3550, 3550, 3550, 3550, 3550, 3550, 3550, 3550, 3550, 3552,
  3550, 3551, 3551, 3551, 3551, 3551, 3551, 3551, 3551, 3551, 3551, 3551, 3551,
  3551, 3551, 3551, 3551, 3551, 3551, 3551, 3551, 3551, 3551, 3551, 3551, 3551,
  3551, 3551, 3551, 3551, 3551, 3551, 3551, 3551, 3551, 3551, 3554, 3555, 3555,
  3555, 3555, 3556, 3556, 3556, 3556, 3556, 3556, 3556, 3556, 3556, 3556, 3556,
  3557, 3557, 3557, 3557, 3558, 3558, 3558, 3558, 3558, 3559, 3559, 3559, 3559,
  3559, 3875, 3561, 3561, 3561, 3561, 3561, 3561, 3561, 3562, 3563, 3564, 3564,
  3564, 3564, 3564, 3564, 3564, 3564, 3564, 3564, 3565, 3566, 3567, 3567, 3568,
  3568, 3568, 3568, 3568, 3568, 3568, 3568, 3568, 3568, 3568, 3570, 3570, 3570,
  3570, 3570, 3571, 3571, 3571, 3571, 3572, 3572, 3572, 3572, 3573, 3573, 3573,
  3573, 3575, 3575, 3575, 3575, 3575, 3575, 3575, 3575, 3575, 3576, 3579, 3579,
  3579, 3579, 3579, 3579, 3579, 3579, 3579, 3579, 3579, 3579, 3579, 3579, 3579,
  3579, 3579, 3579, 3579, 3579, 3579, 3579, 3579, 3579, 3579, 3579, 3579, 3579,
  3580, 3581, 3583, 3584, 3584, 3585, 3585, 3585, 3585, 3585, 3585, 3585, 3585,
  3585, 3585, 3585, 3585, 3585, 3585, 3586, 3586, 3586, 3586, 3586, 3586, 3588,
  3589, 3589, 3590, 3591, 3594, 3595, 3596, 3596, 3596, 3597, 3597, 3597, 3597,
  3597, 3597, 3599, 3607, 3608, 3608, 3608, 3608, 3608, 3608, 3608, 3608, 3610,
  3610, 3610, 3610, 3610, 3612, 3612, 3612, 3612, 3612, 3614, 3614, 3616, 3616,
  3616, 3616, 3616, 3616, 3616, 3616, 3617, 3618, 3620, 3620, 3620, 3620, 3620,
  3620, 3621, 3621, 3621, 3621, 3622, 3622, 3623, 3623, 3623, 3624, 3629, 3629,
  3629, 3629, 3629, 3632, 3630, 3630, 3630, 3630, 3630, 3630, 3631, 3631, 3631,
  3631, 3631, 3631, 3631, 3631, 3631, 3631, 3631, 3631, 3631, 3631, 3631, 3633,
  3634, 3634, 3634, 3634, 3634, 3634, 3634, 3634, 3635, 3635, 3635, 3636, 3636,
  3636, 3636, 3637, 3637, 3638, 3638, 3638, 3639, 3639, 3639, 3639, 3640, 3641,
  3641, 3641, 3641, 3644, 3644, 3644, 3644, 3644, 3646, 3646, 3646, 3646, 3646,
  3646, 3646, 3647, 3649, 3649, 3658, 3658, 3658, 3658, 3658, 3658, 3658, 3658,
  3658, 3659, 3660, 3660, 3660, 3661, 3660, 3660, 3660, 3660, 3660, 3660, 3662,
  3662, 3663, 3664, 3664, 3665, 3665, 3666, 3666, 3666, 3666, 3666, 3666, 3666,
  3666, 3666, 3666, 3666, 3666, 3666, 3666, 3666, 3666, 3666, 3669, 3669, 3669,
  3669, 3669, 3669, 3669, 3669, 3669, 3669, 3669, 3670, 3670, 3670, 3673, 3673,
  3673, 3673, 3673, 3673, 3673, 3673, 3673, 3673, 3673, 3673, 3673, 3673, 3673,
  3673, 3675, 3675, 3675, 3675, 3675, 3675, 3675, 3675, 3675, 3675, 3677, 3677,
  3677, 3678, 3678, 3678, 3678, 3678, 3678, 3678, 3678, 3678, 3678, 3678, 3678,
  3678, 3678, 3678, 3678, 3678, 3678, 3678, 3678, 3678, 3678, 3678, 3678, 3678,
  3678, 3678, 3678, 3678, 3678, 3678, 3678, 3678, 3678, 3678, 3682, 3682, 3682,
  3682, 3683, 3683, 3683, 3685, 3685, 3685, 3685, 3685, 3685, 3685, 3685, 3687,
  3688, 3688, 3690, 3690, 3690, 3691, 3691, 3691, 3691, 3691, 3691, 3709, 3691,
  3691, 3691, 3691, 3691, 3691, 3691, 3691, 3691, 3691, 3691, 3691, 3691, 3691,
  3691, 3691, 3691, 3691, 3691, 3691, 3691, 3691, 3691, 3691, 3691, 3691, 3691,
  3691, 3694, 3695, 3695, 3695, 3695, 3697, 3698, 3699, 3699, 3699, 3699, 3700,
  3700, 3700, 3700, 3700, 3701, 3701, 3701, 3701, 3701, 3701, 3701, 3701, 3701,
  3704, 3707, 3705, 3707, 3707, 3707, 3707, 3707, 3707, 3707, 3708, 3709, 3709,
  3709, 3709, 3711, 3712, 3712, 3713, 3713, 3713, 3714, 3714, 3714, 3714, 3714,
  3714, 3714, 3714, 3714, 3714, 3715, 3715, 3715, 3717, 3717, 3717, 3717, 3717,
  3717, 3717, 3717, 3718, 3719, 3719, 3719, 3719, 3720, 3722, 3722, 3724, 3723,
  3723, 3723, 3723, 3723, 3723, 3723, 3723, 3723, 3723, 3723, 3723, 3723, 3723,
  3723, 3723, 3875, 3723, 3723, 3723, 3723, 3723, 3723, 3723, 3723, 3725, 3725,
  3725, 3725, 3725, 3726, 3726, 3726, 3726, 3727, 3727, 3727, 3727, 3727, 3728,
  3728, 3728, 3728, 3730, 3730, 3730, 3730, 3730, 3730, 3730, 3730, 3730, 3730,
  3732, 3732, 3733, 3735, 3735, 3735, 3735, 3737, 3737, 3737, 3737, 3737, 3737,
  3737, 3737, 3737, 3737, 3737, 3737, 3737, 3737, 3738, 3739, 3740, 3740, 3740,
  3741, 3741, 3741, 3741, 3741, 3741, 3741, 3744, 3746, 3747, 3747, 3747, 3747,
  3747, 3749, 3749, 3750, 3751, 3752, 3753, 3754, 3754, 3755, 3756, 3756, 3756,
  3756, 3756, 3757, 3757, 3757, 3757, 3757, 3757, 3758, 3758, 3759, 3760, 3761,
  3762, 3763, 3764, 3764, 3764, 3764, 3764, 3764, 3764, 3764, 3765, 3766, 3767,
  3770, 3770, 3770, 3775, 3775, 3775, 3775, 3775, 3777, 3777, 3777, 3777, 3777,
  3777, 3777, 3778, 3778, 3779, 3779, 3781, 3781, 3781, 3782, 3782, 3782, 3782,
  3783, 3785, 3786, 3787, 3787, 3788, 3789, 3791, 3792, 3793, 3795, 3796, 3797,
  3797, 3797, 3797, 3797, 3799, 3799, 3799, 3799, 3799, 3799, 3799, 3800, 3802,
  3803, 3804, 3804, 3805, 3805, 3805, 3806, 3806, 3807, 3807, 3808, 3808, 3809,
  3809, 3810, 3810, 3810, 3810, 3812, 3812, 3812, 3813, 3813, 3814, 3814, 3814,
  3814, 3815, 3815, 3815, 3815, 3816, 3816, 3816, 3816, 3818, 3818, 3818, 3818,
  3818, 3818, 3818, 3818, 3820, 3820, 3820, 3821, 3821, 3821, 3821, 3821, 3821,
  3832, 3821, 3821, 3832, 3821, 3821, 3821, 3821, 3821, 3821, 3821, 3821, 3821,
  3822, 3822, 3822, 3823, 3823, 3823, 3824, 3824, 3824, 3824, 3824, 3824, 3825,
  3825, 3825, 3825, 3825, 3825, 3825, 3825, 3825, 3825, 3825, 3825, 3825, 3825,
  3825, 3825, 3825, 3825, 3825, 3825, 3825, 3825, 3825, 3825, 3825, 3825, 3825,
  3825, 3831, 3831, 3831, 3832, 3833, 3833, 3833, 3833, 3833, 3833, 3833, 3833,
  3833, 3833, 3833, 3835, 3840, 3840, 3840, 3840, 3840, 3840, 3840, 3840, 3840,
  3841, 3842, 3844, 3844, 3844, 3844, 3844, 3844, 3844, 3874, 3844, 3844, 3844,
  3847, 3844, 3844, 3844, 3844, 3844, 3847, 3847, 3847, 3847, 3850, 3850, 3853,
  3851, 3851, 3851, 3851, 3851, 3851, 3851, 3851, 3851, 3851, 3851, 3851, 3851,
  3851, 3851, 3851, 3851, 3851, 3851, 3851, 3851, 3851, 3851, 3851, 3851, 3851,
  3851, 3852, 3852, 3852, 3854, 3854, 3854, 3856, 3857, 3858, 3858, 3858, 3858,
  3858, 3858, 3858, 3858, 3858, 3858, 3858, 3858, 3858, 3858, 3858, 3858, 3858,
  3858, 3858, 3858, 3859, 3859, 3859, 3859, 3860, 3860, 3860, 3860, 3860, 3860,
  3860, 3860, 3860, 3860, 3860, 3860, 3860, 3860, 3860, 3862, 3862, 3862, 3862,
  3862, 3862, 3862, 3862, 3862, 3862, 3862, 3862, 3862, 3862, 3862, 3862, 3862,
  3864, 3864, 3865, 3869, 3869, 3869, 3870, 3870, 3870, 3870, 3870, 3871, 3871,
  3871, 3871, 3871, 3871, 3871, 3871, 3873, 3874, 3874, 3874, 3874, 3875, 3875,
  3875, 3875, 3875, 3875, 3875, 3875, 3875, 3875, 3875, 3875, 3875, 3875, 3875,
  3875, 3875, 3875, 3875, 3875, 3875, 3875, 3875, 3875, 3875, 3875, 3875, 3875,
  3875, 3875, 3875, 3878, 3880, 3880, 3880, 3880, 3882, 3885, 3885, 3885, 3885,
  3885, 3885, 3885, 3885, 3885, 3885, 3885, 3885, 3885, 3885, 3885, 3885, 3885,
  3886, 3887, 3887, 3887, 3888, 3888, 3888, 3888, 3888, 3888, 3888, 3888, 3888,
  3888, 3888, 3888, 3888, 3888, 3888, 3888, 3888, 3888, 3888, 3888, 3889, 3889,
  3889, 3889, 3889, 3889, 3889, 3890, 3890, 3890, 3890, 3890, 3890, 3890, 3891,
  3891, 3891, 3891, 3891, 3891, 3891, 3892, 3893, 3893, 3893, 3895, 3895, 3895,
  3895, 3896, 3896, 3896, 3896, 3896, 3898, 3898, 3898, 3898, 3898, 3898, 3898,
  3898, 3898, 3898, 3898, 3898, 3900, 3900, 3902, 3902, 3903, 3904, 3909, 3909,
  3909, 3909, 3909, 3909, 3909, 3909, 3910, 3911, 3911, 3912, 3912, 3913, 3915,
  3915, 3916, 3916, 3916, 3918, 3919, 3920, 3921, 3921, 3921, 3922, 3922, 3922,
  3922, 3922, 3922, 3922, 3922, 3922, 3923, 3925, 3925, 3925, 3925, 3926, 3926,
  3926, 3926, 3927, 3928, 3929, 3930, 3930, 3931, 3933, 3934, 3936, 3936, 3936,
  3937, 3937, 3938, 3939, 3939, 3939, 3939, 3939, 3940, 3941, 3941, 3941, 3942,
  3943, 3944, 3945, 3945, 3945, 3945, 3946, 3950, 3950, 3950, 3950, 3950, 3951,
  3951, 3951, 3951, 3951, 3951, 3951, 3951, 3953, 3953, 3953, 3953, 3953, 3953,
  3953, 3953, 3953, 3953, 3953, 3953, 3953, 3953, 3953, 3954, 3956, 3956, 3956,
  3956, 3956, 3956, 3956, 3956, 3956, 3956, 3958, 3959, 3959, 3959, 3960, 3960,
  3960, 3960, 3960, 3960, 3960, 3960, 3960, 3960, 3960, 3960, 3960, 3962, 3962,
  3962, 3962, 3962, 3964, 3965, 3966, 3966, 3966, 3967, 3971, 3971, 3971, 3971,
  3971, 3971, 3971, 3971, 3971, 3971, 3971, 3971, 3971, 3971, 3971, 3971, 3971,
  3971, 3971, 3971, 3971, 3975, 3975, 3976, 3977, 3977, 3977, 3977, 3977, 3977,
  3977, 3977, 3977, 3977, 3977, 3977, 3978, 3978, 3978, 3979, 3979, 3979, 3980,
  3980, 3980, 3981, 3981, 3981, 3981, 3981, 3981, 3981, 3984, 3984, 3984, 3984,
  3984, 3984, 3984, 3984, 3984, 3984, 3984, 3984, 3984, 3987, 3988, 3988, 3988,
  3988, 3990, 3991, 3992, 3992, 3992, 3992, 3995, 3995, 3995, 3995, 3995, 3995,
  3995, 3995, 3995, 3995, 3995, 3995, 3996, 3996, 8003, 8004, 8006, 8007, 8009,
  8010, 8011,
];

export const MELBOURNE_POSTCODES = new Set(MELBOURNE_POSTCODES_ARRAY);
