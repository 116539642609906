import { GetSimpleThreadQueryResult } from '@tapestry/shared/graphql';

// TODO
// hide uneeded viz
// mocke response for each chart

type MockedRes = {
  maleGenZ: Partial<GetSimpleThreadQueryResult>;
  femaleGenZ: Partial<GetSimpleThreadQueryResult>;
  millennialsMale: Partial<GetSimpleThreadQueryResult>;
  millennialsFemale: Partial<GetSimpleThreadQueryResult>;
  genXMale: Partial<GetSimpleThreadQueryResult>;
  genXFemale: Partial<GetSimpleThreadQueryResult>;
  babyBoomMale: Partial<GetSimpleThreadQueryResult>;
  babyBoomFemale: Partial<GetSimpleThreadQueryResult>;
  tradMale: Partial<GetSimpleThreadQueryResult>;
  tradFemale: Partial<GetSimpleThreadQueryResult>;
};

export const MOCK_GET_SIMPLE_THREAD: MockedRes = {
  maleGenZ: {
    error: undefined,
    data: {
      threadV4: {
        title: 'Male 16 - 22 years old',
        id: 'maleGenZ',
        threadType: 'dem',
      },
    },
  },
  femaleGenZ: {
    error: undefined,
    data: {
      threadV4: {
        title: 'Female 16 - 22 years old',
        id: 'femaleGenZ',
        threadType: 'dem',
      },
    },
  },
  millennialsMale: {
    error: undefined,
    data: {
      threadV4: {
        title: 'Male 32 - 45 years old',
        id: 'millennialsMale',
        threadType: 'dem',
      },
    },
  },
  millennialsFemale: {
    error: undefined,
    data: {
      threadV4: {
        title: 'Female 32 - 45 years old',
        id: 'millennialsFemale',
        threadType: 'dem',
      },
    },
  },
  genXMale: {
    error: undefined,
    data: {
      threadV4: {
        title: 'Male 57 - 67 years old',
        id: 'genXMale',
        threadType: 'dem',
      },
    },
  },
  genXFemale: {
    error: undefined,
    data: {
      threadV4: {
        title: 'Female 57 - 67 years old',
        id: 'genXFemale',
        threadType: 'dem',
      },
    },
  },
  babyBoomMale: {
    error: undefined,
    data: {
      threadV4: {
        title: 'Male 65 - 72 years old',
        id: 'babyBoomMale',
        threadType: 'dem',
      },
    },
  },
  babyBoomFemale: {
    error: undefined,
    data: {
      threadV4: {
        title: 'Female 65 - 72 years old',
        id: 'babyBoomFemale',
        threadType: 'dem',
      },
    },
  },
  tradMale: {
    error: undefined,
    data: {
      threadV4: {
        title: 'Male 75+ years old',
        id: 'tradMale',
        threadType: 'dem',
      },
    },
  },
  tradFemale: {
    error: undefined,
    data: {
      threadV4: {
        title: 'Female 75+ years old',
        id: 'tradFemale',
        threadType: 'dem',
      },
    },
  },
};

export const MOCK_GET_SIMPLE_THREAD_UID = Object.keys(MOCK_GET_SIMPLE_THREAD);
