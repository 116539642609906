import { Operation } from '@apollo/client';
import { THREAD_TYPE } from '@tapestry/types';
import { MOCK_GET_SIMPLE_THREAD } from './data/demographics/get-simple-thread';
import { MOCK_BEST_TIMES } from './data/heartbeat-best-times';
import { MOCK_HEARTBEAT_BAR_CHART } from './data/heartbeat-chart-bar';
import { getHeartbeatBarChartDataChart } from './data/heartbeat-main-chart';
import { MOCK_HEARTBEAT_SUMMARIES } from './data/heartbeat-summaries';
import { MELBOURNE_POSTCODES } from './data/postcods/melbourne-postcodes';
import {
  isDummyShopOrGroup,
  shouldMockMeasureForDummyShop,
} from './utils/dummy-shops';
import { requiresMocking } from './utils/requires-mocking';
import { MOCK_SCOPE_TYPES } from './mock-subject';

const getHeartbeatMockResponse = (operation: Operation) => {
  const {
    operationName,
    variables: {
      uid,
      threadType,
      measure,
      startDate,
      endDate,
      startDateCompare,
      endDateCompare,
    },
    variables,
  } = operation;

  if (operationName === 'getSimpleThread') {
    if (MOCK_GET_SIMPLE_THREAD[uid]) {
      return MOCK_GET_SIMPLE_THREAD[uid];
    }

    if (MELBOURNE_POSTCODES.has(Number(uid))) {
      return {
        data: {
          threadV4: {
            id: uid,
            threadType: 'postcode',
            title: uid,
            __typename: 'ThreadV4Type',
          },
        },
      };
    }
  }

  if (
    !requiresMocking(variables) ||
    (isDummyShopOrGroup(variables) &&
      !shouldMockMeasureForDummyShop(measure) &&
      !MOCK_SCOPE_TYPES.includes(variables.scopeType))
  ) {
    return;
  }

  if (operationName === 'getHeartbeatChartMainCompare') {
    const MOCK_HEARTBEAT_CHART = getHeartbeatBarChartDataChart({
      startDate,
      endDate,
      startDateCompare,
      endDateCompare,
    });

    return MOCK_HEARTBEAT_CHART[measure];
  }

  if (operationName === 'getHeartbeatSummaries') {
    return MOCK_HEARTBEAT_SUMMARIES;
  }

  if (operationName === 'getHeartbeatHeatmapChartData') {
    return MOCK_BEST_TIMES[measure];
  }

  if (operationName === 'getHeartbeatBarChartData') {
    const MOCK_HEARTBEAT_BAR_CHART_DATA = {
      [THREAD_TYPE.DEPARTMENT]: MOCK_HEARTBEAT_BAR_CHART.department,
      [THREAD_TYPE.CATEGORY]: MOCK_HEARTBEAT_BAR_CHART.category,
      [THREAD_TYPE.PRODUCT]: MOCK_HEARTBEAT_BAR_CHART.product,
    };

    return MOCK_HEARTBEAT_BAR_CHART_DATA[threadType];
  }
};

export { getHeartbeatMockResponse };
