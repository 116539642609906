import { GetHeartbeatHeatmapChartDataQueryResult } from '@tapestry/shared/graphql';
import { MeasureSlug } from '@tapestry/types';

interface IBestTimesResponse {
  [MeasureSlug.TotalSales]: Partial<GetHeartbeatHeatmapChartDataQueryResult>;
  [MeasureSlug.Profitability]: Partial<GetHeartbeatHeatmapChartDataQueryResult>;
  [MeasureSlug.CaptureRate]: Partial<GetHeartbeatHeatmapChartDataQueryResult>;
  [MeasureSlug.ReturningVisitor]: Partial<GetHeartbeatHeatmapChartDataQueryResult>;
  [MeasureSlug.Transactions]: Partial<GetHeartbeatHeatmapChartDataQueryResult>;
  [MeasureSlug.BasketValue]: Partial<GetHeartbeatHeatmapChartDataQueryResult>;
  [MeasureSlug.BasketProfit]: Partial<GetHeartbeatHeatmapChartDataQueryResult>;
  [MeasureSlug.Margin]: Partial<GetHeartbeatHeatmapChartDataQueryResult>;
  [MeasureSlug.PromotionalMixOfSales]: Partial<GetHeartbeatHeatmapChartDataQueryResult>;
}

export const MOCK_BEST_TIMES: IBestTimesResponse = {
  [MeasureSlug.TotalSales]: {
    data: {
      heartbeatChartHeatmapGridV2: {
        data: [
          {
            name: '1_08',
            value: 439.86,
            __typename: 'DataValue',
          },
          {
            name: '4_08',
            value: 439.86,
            __typename: 'DataValue',
          },
          {
            name: '1_09',
            value: 455.63,
            __typename: 'DataValue',
          },
          {
            name: '4_09',
            value: 455.63,
            __typename: 'DataValue',
          },
          {
            name: '1_10',
            value: 408.72,
            __typename: 'DataValue',
          },
          {
            name: '4_10',
            value: 408.72,
            __typename: 'DataValue',
          },
          {
            name: '1_11',
            value: 508.4,
            __typename: 'DataValue',
          },
          {
            name: '4_11',
            value: 508.4,
            __typename: 'DataValue',
          },
          {
            name: '1_12',
            value: 887.02,
            __typename: 'DataValue',
          },
          {
            name: '4_12',
            value: 887.02,
            __typename: 'DataValue',
          },
          {
            name: '1_13',
            value: 752.46,
            __typename: 'DataValue',
          },
          {
            name: '4_13',
            value: 752.46,
            __typename: 'DataValue',
          },
          {
            name: '1_14',
            value: 641.63,
            __typename: 'DataValue',
          },
          {
            name: '4_14',
            value: 641.63,
            __typename: 'DataValue',
          },
          {
            name: '1_15',
            value: 684.75,
            __typename: 'DataValue',
          },
          {
            name: '4_15',
            value: 684.75,
            __typename: 'DataValue',
          },
          {
            name: '1_16',
            value: 941.65,
            __typename: 'DataValue',
          },
          {
            name: '4_16',
            value: 941.65,
            __typename: 'DataValue',
          },
          {
            name: '1_17',
            value: 1076.6,
            __typename: 'DataValue',
          },
          {
            name: '4_17',
            value: 1076.6,
            __typename: 'DataValue',
          },
          {
            name: '1_18',
            value: 1186.6,
            __typename: 'DataValue',
          },
          {
            name: '4_18',
            value: 1186.6,
            __typename: 'DataValue',
          },
          {
            name: '1_19',
            value: 1055.34,
            __typename: 'DataValue',
          },
          {
            name: '4_19',
            value: 1055.34,
            __typename: 'DataValue',
          },
          {
            name: '1_20',
            value: 422.57,
            __typename: 'DataValue',
          },
          {
            name: '4_20',
            value: 422.57,
            __typename: 'DataValue',
          },
          {
            name: '1_21',
            value: 151.53,
            __typename: 'DataValue',
          },
          {
            name: '4_21',
            value: 151.53,
            __typename: 'DataValue',
          },
          {
            name: '2_06',
            value: 12.58,
            __typename: 'DataValue',
          },
          {
            name: '2_07',
            value: 130.77,
            __typename: 'DataValue',
          },
          {
            name: '2_08',
            value: 374.98,
            __typename: 'DataValue',
          },
          {
            name: '2_09',
            value: 486.78,
            __typename: 'DataValue',
          },
          {
            name: '2_10',
            value: 531.11,
            __typename: 'DataValue',
          },
          {
            name: '2_11',
            value: 738.94,
            __typename: 'DataValue',
          },
          {
            name: '2_12',
            value: 876.45,
            __typename: 'DataValue',
          },
          {
            name: '2_13',
            value: 678.49,
            __typename: 'DataValue',
          },
          {
            name: '2_14',
            value: 636.22,
            __typename: 'DataValue',
          },
          {
            name: '2_15',
            value: 494.99,
            __typename: 'DataValue',
          },
          {
            name: '2_16',
            value: 492.58,
            __typename: 'DataValue',
          },
          {
            name: '2_17',
            value: 1782.01,
            __typename: 'DataValue',
          },
          {
            name: '2_18',
            value: 1196.68,
            __typename: 'DataValue',
          },
          {
            name: '2_19',
            value: 686.73,
            __typename: 'DataValue',
          },
          {
            name: '2_20',
            value: 396.47,
            __typename: 'DataValue',
          },
          {
            name: '2_21',
            value: 250.18,
            __typename: 'DataValue',
          },
          {
            name: '3_07',
            value: 152.66,
            __typename: 'DataValue',
          },
          {
            name: '3_08',
            value: 338.66,
            __typename: 'DataValue',
          },
          {
            name: '3_09',
            value: 641.47,
            __typename: 'DataValue',
          },
          {
            name: '3_10',
            value: 330.84,
            __typename: 'DataValue',
          },
          {
            name: '3_11',
            value: 637.87,
            __typename: 'DataValue',
          },
          {
            name: '3_12',
            value: 859.05,
            __typename: 'DataValue',
          },
          {
            name: '3_13',
            value: 672.22,
            __typename: 'DataValue',
          },
          {
            name: '3_14',
            value: 475.65,
            __typename: 'DataValue',
          },
          {
            name: '3_15',
            value: 691.07,
            __typename: 'DataValue',
          },
          {
            name: '3_16',
            value: 968.36,
            __typename: 'DataValue',
          },
          {
            name: '3_17',
            value: 1223.6,
            __typename: 'DataValue',
          },
          {
            name: '3_18',
            value: 29.14,
            __typename: 'DataValue',
          },
          {
            name: '5_07',
            value: 152.66,
            __typename: 'DataValue',
          },
          {
            name: '5_08',
            value: 338.66,
            __typename: 'DataValue',
          },
          {
            name: '5_09',
            value: 641.47,
            __typename: 'DataValue',
          },
          {
            name: '5_10',
            value: 330.84,
            __typename: 'DataValue',
          },
          {
            name: '5_11',
            value: 637.87,
            __typename: 'DataValue',
          },
          {
            name: '5_12',
            value: 859.05,
            __typename: 'DataValue',
          },
        ],
        __typename: 'heartbeatChartHeatmapGridType',
      },
    },
  },
  [MeasureSlug.Profitability]: {
    data: {
      heartbeatChartHeatmapGridV2: {
        data: [
          {
            name: '1_08',
            value: 439.86,
            __typename: 'DataValue',
          },
          {
            name: '4_08',
            value: 439.86,
            __typename: 'DataValue',
          },
          {
            name: '1_09',
            value: 455.63,
            __typename: 'DataValue',
          },
          {
            name: '4_09',
            value: 455.63,
            __typename: 'DataValue',
          },
          {
            name: '1_10',
            value: 408.72,
            __typename: 'DataValue',
          },
          {
            name: '4_10',
            value: 408.72,
            __typename: 'DataValue',
          },
          {
            name: '1_11',
            value: 508.4,
            __typename: 'DataValue',
          },
          {
            name: '4_11',
            value: 508.4,
            __typename: 'DataValue',
          },
          {
            name: '1_12',
            value: 887.02,
            __typename: 'DataValue',
          },
          {
            name: '4_12',
            value: 887.02,
            __typename: 'DataValue',
          },
          {
            name: '1_13',
            value: 752.46,
            __typename: 'DataValue',
          },
          {
            name: '4_13',
            value: 752.46,
            __typename: 'DataValue',
          },
          {
            name: '1_14',
            value: 641.63,
            __typename: 'DataValue',
          },
          {
            name: '4_14',
            value: 641.63,
            __typename: 'DataValue',
          },
          {
            name: '1_15',
            value: 684.75,
            __typename: 'DataValue',
          },
          {
            name: '4_15',
            value: 684.75,
            __typename: 'DataValue',
          },
          {
            name: '1_16',
            value: 941.65,
            __typename: 'DataValue',
          },
          {
            name: '4_16',
            value: 941.65,
            __typename: 'DataValue',
          },
          {
            name: '1_17',
            value: 1076.6,
            __typename: 'DataValue',
          },
          {
            name: '4_17',
            value: 1076.6,
            __typename: 'DataValue',
          },
          {
            name: '1_18',
            value: 1186.6,
            __typename: 'DataValue',
          },
          {
            name: '4_18',
            value: 1186.6,
            __typename: 'DataValue',
          },
          {
            name: '1_19',
            value: 1055.34,
            __typename: 'DataValue',
          },
          {
            name: '4_19',
            value: 1055.34,
            __typename: 'DataValue',
          },
          {
            name: '1_20',
            value: 422.57,
            __typename: 'DataValue',
          },
          {
            name: '4_20',
            value: 422.57,
            __typename: 'DataValue',
          },
          {
            name: '1_21',
            value: 151.53,
            __typename: 'DataValue',
          },
          {
            name: '4_21',
            value: 151.53,
            __typename: 'DataValue',
          },
          {
            name: '2_06',
            value: 12.58,
            __typename: 'DataValue',
          },
          {
            name: '2_07',
            value: 130.77,
            __typename: 'DataValue',
          },
          {
            name: '2_08',
            value: 374.98,
            __typename: 'DataValue',
          },
          {
            name: '2_09',
            value: 486.78,
            __typename: 'DataValue',
          },
          {
            name: '2_10',
            value: 531.11,
            __typename: 'DataValue',
          },
          {
            name: '2_11',
            value: 738.94,
            __typename: 'DataValue',
          },
          {
            name: '2_12',
            value: 876.45,
            __typename: 'DataValue',
          },
          {
            name: '2_13',
            value: 678.49,
            __typename: 'DataValue',
          },
          {
            name: '2_14',
            value: 636.22,
            __typename: 'DataValue',
          },
          {
            name: '2_15',
            value: 494.99,
            __typename: 'DataValue',
          },
          {
            name: '2_16',
            value: 492.58,
            __typename: 'DataValue',
          },
          {
            name: '2_17',
            value: 1782.01,
            __typename: 'DataValue',
          },
          {
            name: '2_18',
            value: 1196.68,
            __typename: 'DataValue',
          },
          {
            name: '2_19',
            value: 686.73,
            __typename: 'DataValue',
          },
          {
            name: '2_20',
            value: 396.47,
            __typename: 'DataValue',
          },
          {
            name: '2_21',
            value: 250.18,
            __typename: 'DataValue',
          },
          {
            name: '3_07',
            value: 152.66,
            __typename: 'DataValue',
          },
          {
            name: '3_08',
            value: 338.66,
            __typename: 'DataValue',
          },
          {
            name: '3_09',
            value: 641.47,
            __typename: 'DataValue',
          },
          {
            name: '3_10',
            value: 330.84,
            __typename: 'DataValue',
          },
          {
            name: '3_11',
            value: 637.87,
            __typename: 'DataValue',
          },
          {
            name: '3_12',
            value: 859.05,
            __typename: 'DataValue',
          },
          {
            name: '3_13',
            value: 672.22,
            __typename: 'DataValue',
          },
          {
            name: '3_14',
            value: 475.65,
            __typename: 'DataValue',
          },
          {
            name: '3_15',
            value: 691.07,
            __typename: 'DataValue',
          },
          {
            name: '3_16',
            value: 968.36,
            __typename: 'DataValue',
          },
          {
            name: '3_17',
            value: 1223.6,
            __typename: 'DataValue',
          },
          {
            name: '3_18',
            value: 29.14,
            __typename: 'DataValue',
          },
          {
            name: '5_07',
            value: 152.66,
            __typename: 'DataValue',
          },
          {
            name: '5_08',
            value: 338.66,
            __typename: 'DataValue',
          },
          {
            name: '5_09',
            value: 641.47,
            __typename: 'DataValue',
          },
          {
            name: '5_10',
            value: 330.84,
            __typename: 'DataValue',
          },
          {
            name: '5_11',
            value: 637.87,
            __typename: 'DataValue',
          },
          {
            name: '5_12',
            value: 859.05,
            __typename: 'DataValue',
          },
        ],
        __typename: 'heartbeatChartHeatmapGridType',
      },
    },
  },
  [MeasureSlug.CaptureRate]: {
    data: {
      heartbeatChartHeatmapGridV2: {
        data: [
          {
            name: '1_08',
            value: Math.max(15, Math.random() * 50),
            __typename: 'DataValue',
          },
          {
            name: '4_08',
            value: Math.max(15, Math.random() * 50),
            __typename: 'DataValue',
          },
          {
            name: '1_09',
            value: Math.max(15, Math.random() * 50),
            __typename: 'DataValue',
          },
          {
            name: '4_09',
            value: Math.max(15, Math.random() * 50),
            __typename: 'DataValue',
          },
          {
            name: '1_10',
            value: Math.max(15, Math.random() * 50),
            __typename: 'DataValue',
          },
          {
            name: '4_10',
            value: Math.max(15, Math.random() * 50),
            __typename: 'DataValue',
          },
          {
            name: '1_11',
            value: Math.max(15, Math.random() * 50),
            __typename: 'DataValue',
          },
          {
            name: '4_11',
            value: Math.max(15, Math.random() * 50),
            __typename: 'DataValue',
          },
          {
            name: '1_12',
            value: Math.max(15, Math.random() * 50),
            __typename: 'DataValue',
          },
          {
            name: '4_12',
            value: Math.max(15, Math.random() * 50),
            __typename: 'DataValue',
          },
          {
            name: '1_13',
            value: Math.max(15, Math.random() * 50),
            __typename: 'DataValue',
          },
          {
            name: '4_13',
            value: Math.max(15, Math.random() * 50),
            __typename: 'DataValue',
          },
          {
            name: '1_14',
            value: Math.max(15, Math.random() * 50),
            __typename: 'DataValue',
          },
          {
            name: '4_14',
            value: Math.max(15, Math.random() * 50),
            __typename: 'DataValue',
          },
          {
            name: '1_15',
            value: Math.max(15, Math.random() * 50),
            __typename: 'DataValue',
          },
          {
            name: '4_15',
            value: Math.max(15, Math.random() * 50),
            __typename: 'DataValue',
          },
          {
            name: '1_16',
            value: Math.max(15, Math.random() * 50),
            __typename: 'DataValue',
          },
          {
            name: '4_16',
            value: Math.max(15, Math.random() * 50),
            __typename: 'DataValue',
          },
          {
            name: '1_17',
            value: Math.max(15, Math.random() * 50),
            __typename: 'DataValue',
          },
          {
            name: '4_17',
            value: Math.max(15, Math.random() * 50),
            __typename: 'DataValue',
          },
          {
            name: '1_18',
            value: Math.max(15, Math.random() * 50),
            __typename: 'DataValue',
          },
          {
            name: '4_18',
            value: Math.max(15, Math.random() * 50),
            __typename: 'DataValue',
          },
          {
            name: '1_19',
            value: Math.max(15, Math.random() * 50),
            __typename: 'DataValue',
          },
          {
            name: '4_19',
            value: Math.max(15, Math.random() * 50),
            __typename: 'DataValue',
          },
          {
            name: '1_20',
            value: Math.max(15, Math.random() * 50),
            __typename: 'DataValue',
          },
          {
            name: '4_20',
            value: Math.max(15, Math.random() * 50),
            __typename: 'DataValue',
          },
          {
            name: '1_21',
            value: Math.max(15, Math.random() * 50),
            __typename: 'DataValue',
          },
          {
            name: '4_21',
            value: Math.max(15, Math.random() * 50),
            __typename: 'DataValue',
          },
          {
            name: '2_06',
            value: Math.max(15, Math.random() * 50),
            __typename: 'DataValue',
          },
          {
            name: '2_07',
            value: Math.max(15, Math.random() * 50),
            __typename: 'DataValue',
          },
          {
            name: '2_08',
            value: Math.max(15, Math.random() * 50),
            __typename: 'DataValue',
          },
          {
            name: '2_09',
            value: Math.max(15, Math.random() * 50),
            __typename: 'DataValue',
          },
          {
            name: '2_10',
            value: Math.max(15, Math.random() * 50),
            __typename: 'DataValue',
          },
          {
            name: '2_11',
            value: Math.max(15, Math.random() * 50),
            __typename: 'DataValue',
          },
          {
            name: '2_12',
            value: Math.max(15, Math.random() * 50),
            __typename: 'DataValue',
          },
          {
            name: '2_13',
            value: Math.max(15, Math.random() * 50),
            __typename: 'DataValue',
          },
          {
            name: '2_14',
            value: Math.max(15, Math.random() * 50),
            __typename: 'DataValue',
          },
          {
            name: '2_15',
            value: Math.max(15, Math.random() * 50),
            __typename: 'DataValue',
          },
          {
            name: '2_16',
            value: Math.max(15, Math.random() * 50),
            __typename: 'DataValue',
          },
          {
            name: '2_17',
            value: Math.max(15, Math.random() * 50),
            __typename: 'DataValue',
          },
          {
            name: '2_18',
            value: Math.max(15, Math.random() * 50),
            __typename: 'DataValue',
          },
          {
            name: '2_19',
            value: Math.max(15, Math.random() * 50),
            __typename: 'DataValue',
          },
          {
            name: '2_20',
            value: Math.max(15, Math.random() * 50),
            __typename: 'DataValue',
          },
          {
            name: '2_21',
            value: Math.max(15, Math.random() * 50),
            __typename: 'DataValue',
          },
          {
            name: '3_07',
            value: Math.max(15, Math.random() * 50),
            __typename: 'DataValue',
          },
          {
            name: '3_08',
            value: Math.max(15, Math.random() * 50),
            __typename: 'DataValue',
          },
          {
            name: '3_09',
            value: Math.max(15, Math.random() * 50),
            __typename: 'DataValue',
          },
          {
            name: '3_10',
            value: Math.max(15, Math.random() * 50),
            __typename: 'DataValue',
          },
          {
            name: '3_11',
            value: Math.max(15, Math.random() * 50),
            __typename: 'DataValue',
          },
          {
            name: '3_12',
            value: Math.max(15, Math.random() * 50),
            __typename: 'DataValue',
          },
          {
            name: '3_13',
            value: Math.max(15, Math.random() * 50),
            __typename: 'DataValue',
          },
          {
            name: '3_14',
            value: Math.max(15, Math.random() * 50),
            __typename: 'DataValue',
          },
          {
            name: '3_15',
            value: Math.max(15, Math.random() * 50),
            __typename: 'DataValue',
          },
          {
            name: '3_16',
            value: Math.max(15, Math.random() * 50),
            __typename: 'DataValue',
          },
          {
            name: '3_17',
            value: Math.max(15, Math.random() * 50),
            __typename: 'DataValue',
          },
          {
            name: '3_18',
            value: Math.max(15, Math.random() * 50),
            __typename: 'DataValue',
          },
          {
            name: '5_07',
            value: Math.max(15, Math.random() * 50),
            __typename: 'DataValue',
          },
          {
            name: '5_08',
            value: Math.max(15, Math.random() * 50),
            __typename: 'DataValue',
          },
          {
            name: '5_09',
            value: Math.max(15, Math.random() * 50),
            __typename: 'DataValue',
          },
          {
            name: '5_10',
            value: Math.max(15, Math.random() * 50),
            __typename: 'DataValue',
          },
          {
            name: '5_11',
            value: Math.max(15, Math.random() * 50),
            __typename: 'DataValue',
          },
          {
            name: '5_12',
            value: Math.max(15, Math.random() * 50),
            __typename: 'DataValue',
          },
        ],
        __typename: 'heartbeatChartHeatmapGridType',
      },
    },
  },
  [MeasureSlug.ReturningVisitor]: {
    data: {
      heartbeatChartHeatmapGridV2: {
        data: [
          {
            name: '1_08',
            value: Math.random() * 6,
            __typename: 'DataValue',
          },
          {
            name: '4_08',
            value: Math.random() * 6,
            __typename: 'DataValue',
          },
          {
            name: '1_09',
            value: Math.random() * 6,
            __typename: 'DataValue',
          },
          {
            name: '4_09',
            value: Math.random() * 6,
            __typename: 'DataValue',
          },
          {
            name: '1_10',
            value: Math.random() * 6,
            __typename: 'DataValue',
          },
          {
            name: '4_10',
            value: Math.random() * 6,
            __typename: 'DataValue',
          },
          {
            name: '1_11',
            value: Math.random() * 6,
            __typename: 'DataValue',
          },
          {
            name: '4_11',
            value: Math.random() * 6,
            __typename: 'DataValue',
          },
          {
            name: '1_12',
            value: Math.random() * 6,
            __typename: 'DataValue',
          },
          {
            name: '4_12',
            value: Math.random() * 6,
            __typename: 'DataValue',
          },
          {
            name: '1_13',
            value: Math.random() * 6,
            __typename: 'DataValue',
          },
          {
            name: '4_13',
            value: Math.random() * 6,
            __typename: 'DataValue',
          },
          {
            name: '1_14',
            value: Math.random() * 6,
            __typename: 'DataValue',
          },
          {
            name: '4_14',
            value: Math.random() * 6,
            __typename: 'DataValue',
          },
          {
            name: '1_15',
            value: Math.random() * 6,
            __typename: 'DataValue',
          },
          {
            name: '4_15',
            value: Math.random() * 6,
            __typename: 'DataValue',
          },
          {
            name: '1_16',
            value: Math.random() * 6,
            __typename: 'DataValue',
          },
          {
            name: '4_16',
            value: Math.random() * 6,
            __typename: 'DataValue',
          },
          {
            name: '1_17',
            value: Math.random() * 6,
            __typename: 'DataValue',
          },
          {
            name: '4_17',
            value: Math.random() * 6,
            __typename: 'DataValue',
          },
          {
            name: '1_18',
            value: Math.random() * 6,
            __typename: 'DataValue',
          },
          {
            name: '4_18',
            value: Math.random() * 6,
            __typename: 'DataValue',
          },
          {
            name: '1_19',
            value: Math.random() * 6,
            __typename: 'DataValue',
          },
          {
            name: '4_19',
            value: Math.random() * 6,
            __typename: 'DataValue',
          },
          {
            name: '1_20',
            value: Math.random() * 6,
            __typename: 'DataValue',
          },
          {
            name: '4_20',
            value: Math.random() * 6,
            __typename: 'DataValue',
          },
          {
            name: '1_21',
            value: Math.random() * 6,
            __typename: 'DataValue',
          },
          {
            name: '4_21',
            value: Math.random() * 6,
            __typename: 'DataValue',
          },
          {
            name: '2_06',
            value: Math.random() * 6,
            __typename: 'DataValue',
          },
          {
            name: '2_07',
            value: Math.random() * 6,
            __typename: 'DataValue',
          },
          {
            name: '2_08',
            value: Math.random() * 6,
            __typename: 'DataValue',
          },
          {
            name: '2_09',
            value: Math.random() * 6,
            __typename: 'DataValue',
          },
          {
            name: '2_10',
            value: Math.random() * 6,
            __typename: 'DataValue',
          },
          {
            name: '2_11',
            value: Math.random() * 6,
            __typename: 'DataValue',
          },
          {
            name: '2_12',
            value: Math.random() * 6,
            __typename: 'DataValue',
          },
          {
            name: '2_13',
            value: Math.random() * 6,
            __typename: 'DataValue',
          },
          {
            name: '2_14',
            value: Math.random() * 6,
            __typename: 'DataValue',
          },
          {
            name: '2_15',
            value: Math.random() * 6,
            __typename: 'DataValue',
          },
          {
            name: '2_16',
            value: Math.random() * 6,
            __typename: 'DataValue',
          },
          {
            name: '2_17',
            value: Math.random() * 6,
            __typename: 'DataValue',
          },
          {
            name: '2_18',
            value: Math.random() * 6,
            __typename: 'DataValue',
          },
          {
            name: '2_19',
            value: Math.random() * 6,
            __typename: 'DataValue',
          },
          {
            name: '2_20',
            value: Math.random() * 6,
            __typename: 'DataValue',
          },
          {
            name: '2_21',
            value: Math.random() * 6,
            __typename: 'DataValue',
          },
          {
            name: '3_07',
            value: Math.random() * 6,
            __typename: 'DataValue',
          },
          {
            name: '3_08',
            value: Math.random() * 6,
            __typename: 'DataValue',
          },
          {
            name: '3_09',
            value: Math.random() * 6,
            __typename: 'DataValue',
          },
          {
            name: '3_10',
            value: Math.random() * 6,
            __typename: 'DataValue',
          },
          {
            name: '3_11',
            value: Math.random() * 6,
            __typename: 'DataValue',
          },
          {
            name: '3_12',
            value: Math.random() * 6,
            __typename: 'DataValue',
          },
          {
            name: '3_13',
            value: Math.random() * 6,
            __typename: 'DataValue',
          },
          {
            name: '3_14',
            value: Math.random() * 6,
            __typename: 'DataValue',
          },
          {
            name: '3_15',
            value: Math.random() * 6,
            __typename: 'DataValue',
          },
          {
            name: '3_16',
            value: Math.random() * 6,
            __typename: 'DataValue',
          },
          {
            name: '3_17',
            value: Math.random() * 6,
            __typename: 'DataValue',
          },
          {
            name: '3_18',
            value: Math.random() * 6,
            __typename: 'DataValue',
          },
          {
            name: '5_07',
            value: Math.random() * 6,
            __typename: 'DataValue',
          },
          {
            name: '5_08',
            value: Math.random() * 6,
            __typename: 'DataValue',
          },
          {
            name: '5_09',
            value: Math.random() * 6,
            __typename: 'DataValue',
          },
          {
            name: '5_10',
            value: Math.random() * 6,
            __typename: 'DataValue',
          },
          {
            name: '5_11',
            value: Math.random() * 6,
            __typename: 'DataValue',
          },
          {
            name: '5_12',
            value: Math.random() * 6,
            __typename: 'DataValue',
          },
        ],
        __typename: 'heartbeatChartHeatmapGridType',
      },
    },
  },
  [MeasureSlug.Transactions]: {
    data: {
      heartbeatChartHeatmapGridV2: {
        data: [
          {
            name: '1_08',
            value: Math.random() * 70,
            __typename: 'DataValue',
          },
          {
            name: '4_08',
            value: Math.random() * 70,
            __typename: 'DataValue',
          },
          {
            name: '1_09',
            value: Math.random() * 70,
            __typename: 'DataValue',
          },
          {
            name: '4_09',
            value: Math.random() * 70,
            __typename: 'DataValue',
          },
          {
            name: '1_10',
            value: Math.random() * 70,
            __typename: 'DataValue',
          },
          {
            name: '4_10',
            value: Math.random() * 70,
            __typename: 'DataValue',
          },
          {
            name: '1_11',
            value: Math.random() * 70,
            __typename: 'DataValue',
          },
          {
            name: '4_11',
            value: Math.random() * 70,
            __typename: 'DataValue',
          },
          {
            name: '1_12',
            value: Math.random() * 70,
            __typename: 'DataValue',
          },
          {
            name: '4_12',
            value: Math.random() * 70,
            __typename: 'DataValue',
          },
          {
            name: '1_13',
            value: Math.random() * 70,
            __typename: 'DataValue',
          },
          {
            name: '4_13',
            value: Math.random() * 70,
            __typename: 'DataValue',
          },
          {
            name: '1_14',
            value: Math.random() * 70,
            __typename: 'DataValue',
          },
          {
            name: '4_14',
            value: Math.random() * 70,
            __typename: 'DataValue',
          },
          {
            name: '1_15',
            value: Math.random() * 70,
            __typename: 'DataValue',
          },
          {
            name: '4_15',
            value: Math.random() * 70,
            __typename: 'DataValue',
          },
          {
            name: '1_16',
            value: Math.random() * 70,
            __typename: 'DataValue',
          },
          {
            name: '4_16',
            value: Math.random() * 70,
            __typename: 'DataValue',
          },
          {
            name: '1_17',
            value: Math.random() * 70,
            __typename: 'DataValue',
          },
          {
            name: '4_17',
            value: Math.random() * 70,
            __typename: 'DataValue',
          },
          {
            name: '1_18',
            value: Math.random() * 70,
            __typename: 'DataValue',
          },
          {
            name: '4_18',
            value: Math.random() * 70,
            __typename: 'DataValue',
          },
          {
            name: '1_19',
            value: Math.random() * 70,
            __typename: 'DataValue',
          },
          {
            name: '4_19',
            value: Math.random() * 70,
            __typename: 'DataValue',
          },
          {
            name: '1_20',
            value: Math.random() * 70,
            __typename: 'DataValue',
          },
          {
            name: '4_20',
            value: Math.random() * 70,
            __typename: 'DataValue',
          },
          {
            name: '1_21',
            value: Math.random() * 70,
            __typename: 'DataValue',
          },
          {
            name: '4_21',
            value: Math.random() * 70,
            __typename: 'DataValue',
          },
          {
            name: '2_06',
            value: Math.random() * 70,
            __typename: 'DataValue',
          },
          {
            name: '2_07',
            value: Math.random() * 70,
            __typename: 'DataValue',
          },
          {
            name: '2_08',
            value: Math.random() * 70,
            __typename: 'DataValue',
          },
          {
            name: '2_09',
            value: Math.random() * 70,
            __typename: 'DataValue',
          },
          {
            name: '2_10',
            value: Math.random() * 70,
            __typename: 'DataValue',
          },
          {
            name: '2_11',
            value: Math.random() * 70,
            __typename: 'DataValue',
          },
          {
            name: '2_12',
            value: Math.random() * 70,
            __typename: 'DataValue',
          },
          {
            name: '2_13',
            value: Math.random() * 70,
            __typename: 'DataValue',
          },
          {
            name: '2_14',
            value: Math.random() * 70,
            __typename: 'DataValue',
          },
          {
            name: '2_15',
            value: Math.random() * 70,
            __typename: 'DataValue',
          },
          {
            name: '2_16',
            value: Math.random() * 70,
            __typename: 'DataValue',
          },
          {
            name: '2_17',
            value: Math.random() * 70,
            __typename: 'DataValue',
          },
          {
            name: '2_18',
            value: Math.random() * 70,
            __typename: 'DataValue',
          },
          {
            name: '2_19',
            value: Math.random() * 70,
            __typename: 'DataValue',
          },
          {
            name: '2_20',
            value: Math.random() * 70,
            __typename: 'DataValue',
          },
          {
            name: '2_21',
            value: Math.random() * 70,
            __typename: 'DataValue',
          },
          {
            name: '3_07',
            value: Math.random() * 70,
            __typename: 'DataValue',
          },
          {
            name: '3_08',
            value: Math.random() * 70,
            __typename: 'DataValue',
          },
          {
            name: '3_09',
            value: Math.random() * 70,
            __typename: 'DataValue',
          },
          {
            name: '3_10',
            value: Math.random() * 70,
            __typename: 'DataValue',
          },
          {
            name: '3_11',
            value: Math.random() * 70,
            __typename: 'DataValue',
          },
          {
            name: '3_12',
            value: Math.random() * 70,
            __typename: 'DataValue',
          },
          {
            name: '3_13',
            value: Math.random() * 70,
            __typename: 'DataValue',
          },
          {
            name: '3_14',
            value: Math.random() * 70,
            __typename: 'DataValue',
          },
          {
            name: '3_15',
            value: Math.random() * 70,
            __typename: 'DataValue',
          },
          {
            name: '3_16',
            value: Math.random() * 70,
            __typename: 'DataValue',
          },
          {
            name: '3_17',
            value: Math.random() * 70,
            __typename: 'DataValue',
          },
          {
            name: '3_18',
            value: Math.random() * 70,
            __typename: 'DataValue',
          },
          {
            name: '5_07',
            value: Math.random() * 70,
            __typename: 'DataValue',
          },
          {
            name: '5_08',
            value: Math.random() * 70,
            __typename: 'DataValue',
          },
          {
            name: '5_09',
            value: Math.random() * 70,
            __typename: 'DataValue',
          },
          {
            name: '5_10',
            value: Math.random() * 70,
            __typename: 'DataValue',
          },
          {
            name: '5_11',
            value: Math.random() * 70,
            __typename: 'DataValue',
          },
          {
            name: '5_12',
            value: Math.random() * 70,
            __typename: 'DataValue',
          },
        ],
        __typename: 'heartbeatChartHeatmapGridType',
      },
    },
  },
  [MeasureSlug.BasketValue]: {
    data: {
      heartbeatChartHeatmapGridV2: {
        data: [
          {
            name: '1_08',
            value: Math.random() * 14,
            __typename: 'DataValue',
          },
          {
            name: '4_08',
            value: Math.random() * 14,
            __typename: 'DataValue',
          },
          {
            name: '1_09',
            value: Math.random() * 14,
            __typename: 'DataValue',
          },
          {
            name: '4_09',
            value: Math.random() * 14,
            __typename: 'DataValue',
          },
          {
            name: '1_10',
            value: Math.random() * 14,
            __typename: 'DataValue',
          },
          {
            name: '4_10',
            value: Math.random() * 14,
            __typename: 'DataValue',
          },
          {
            name: '1_11',
            value: Math.random() * 14,
            __typename: 'DataValue',
          },
          {
            name: '4_11',
            value: Math.random() * 14,
            __typename: 'DataValue',
          },
          {
            name: '1_12',
            value: Math.random() * 14,
            __typename: 'DataValue',
          },
          {
            name: '4_12',
            value: Math.random() * 14,
            __typename: 'DataValue',
          },
          {
            name: '1_13',
            value: Math.random() * 14,
            __typename: 'DataValue',
          },
          {
            name: '4_13',
            value: Math.random() * 14,
            __typename: 'DataValue',
          },
          {
            name: '1_14',
            value: Math.random() * 14,
            __typename: 'DataValue',
          },
          {
            name: '4_14',
            value: Math.random() * 14,
            __typename: 'DataValue',
          },
          {
            name: '1_15',
            value: Math.random() * 14,
            __typename: 'DataValue',
          },
          {
            name: '4_15',
            value: Math.random() * 14,
            __typename: 'DataValue',
          },
          {
            name: '1_16',
            value: Math.random() * 14,
            __typename: 'DataValue',
          },
          {
            name: '4_16',
            value: Math.random() * 14,
            __typename: 'DataValue',
          },
          {
            name: '1_17',
            value: Math.random() * 14,
            __typename: 'DataValue',
          },
          {
            name: '4_17',
            value: Math.random() * 14,
            __typename: 'DataValue',
          },
          {
            name: '1_18',
            value: Math.random() * 14,
            __typename: 'DataValue',
          },
          {
            name: '4_18',
            value: Math.random() * 14,
            __typename: 'DataValue',
          },
          {
            name: '1_19',
            value: Math.random() * 14,
            __typename: 'DataValue',
          },
          {
            name: '4_19',
            value: Math.random() * 14,
            __typename: 'DataValue',
          },
          {
            name: '1_20',
            value: Math.random() * 14,
            __typename: 'DataValue',
          },
          {
            name: '4_20',
            value: Math.random() * 14,
            __typename: 'DataValue',
          },
          {
            name: '1_21',
            value: Math.random() * 14,
            __typename: 'DataValue',
          },
          {
            name: '4_21',
            value: Math.random() * 14,
            __typename: 'DataValue',
          },
          {
            name: '2_06',
            value: Math.random() * 14,
            __typename: 'DataValue',
          },
          {
            name: '2_07',
            value: Math.random() * 14,
            __typename: 'DataValue',
          },
          {
            name: '2_08',
            value: Math.random() * 14,
            __typename: 'DataValue',
          },
          {
            name: '2_09',
            value: Math.random() * 14,
            __typename: 'DataValue',
          },
          {
            name: '2_10',
            value: Math.random() * 14,
            __typename: 'DataValue',
          },
          {
            name: '2_11',
            value: Math.random() * 14,
            __typename: 'DataValue',
          },
          {
            name: '2_12',
            value: Math.random() * 14,
            __typename: 'DataValue',
          },
          {
            name: '2_13',
            value: Math.random() * 14,
            __typename: 'DataValue',
          },
          {
            name: '2_14',
            value: Math.random() * 14,
            __typename: 'DataValue',
          },
          {
            name: '2_15',
            value: Math.random() * 14,
            __typename: 'DataValue',
          },
          {
            name: '2_16',
            value: Math.random() * 14,
            __typename: 'DataValue',
          },
          {
            name: '2_17',
            value: Math.random() * 14,
            __typename: 'DataValue',
          },
          {
            name: '2_18',
            value: Math.random() * 14,
            __typename: 'DataValue',
          },
          {
            name: '2_19',
            value: Math.random() * 14,
            __typename: 'DataValue',
          },
          {
            name: '2_20',
            value: Math.random() * 14,
            __typename: 'DataValue',
          },
          {
            name: '2_21',
            value: Math.random() * 14,
            __typename: 'DataValue',
          },
          {
            name: '3_07',
            value: Math.random() * 14,
            __typename: 'DataValue',
          },
          {
            name: '3_08',
            value: Math.random() * 14,
            __typename: 'DataValue',
          },
          {
            name: '3_09',
            value: Math.random() * 14,
            __typename: 'DataValue',
          },
          {
            name: '3_10',
            value: Math.random() * 14,
            __typename: 'DataValue',
          },
          {
            name: '3_11',
            value: Math.random() * 14,
            __typename: 'DataValue',
          },
          {
            name: '3_12',
            value: Math.random() * 14,
            __typename: 'DataValue',
          },
          {
            name: '3_13',
            value: Math.random() * 14,
            __typename: 'DataValue',
          },
          {
            name: '3_14',
            value: Math.random() * 14,
            __typename: 'DataValue',
          },
          {
            name: '3_15',
            value: Math.random() * 14,
            __typename: 'DataValue',
          },
          {
            name: '3_16',
            value: Math.random() * 14,
            __typename: 'DataValue',
          },
          {
            name: '3_17',
            value: Math.random() * 14,
            __typename: 'DataValue',
          },
          {
            name: '3_18',
            value: Math.random() * 14,
            __typename: 'DataValue',
          },
          {
            name: '5_07',
            value: Math.random() * 14,
            __typename: 'DataValue',
          },
          {
            name: '5_08',
            value: Math.random() * 14,
            __typename: 'DataValue',
          },
          {
            name: '5_09',
            value: Math.random() * 14,
            __typename: 'DataValue',
          },
          {
            name: '5_10',
            value: Math.random() * 14,
            __typename: 'DataValue',
          },
          {
            name: '5_11',
            value: Math.random() * 14,
            __typename: 'DataValue',
          },
          {
            name: '5_12',
            value: Math.random() * 14,
            __typename: 'DataValue',
          },
        ],
        __typename: 'heartbeatChartHeatmapGridType',
      },
    },
  },
  [MeasureSlug.BasketProfit]: {
    data: {
      heartbeatChartHeatmapGridV2: {
        data: [
          {
            name: '1_08',
            value: Math.random() * 12,
            __typename: 'DataValue',
          },
          {
            name: '4_08',
            value: Math.random() * 12,
            __typename: 'DataValue',
          },
          {
            name: '1_09',
            value: Math.random() * 12,
            __typename: 'DataValue',
          },
          {
            name: '4_09',
            value: Math.random() * 12,
            __typename: 'DataValue',
          },
          {
            name: '1_10',
            value: Math.random() * 12,
            __typename: 'DataValue',
          },
          {
            name: '4_10',
            value: Math.random() * 12,
            __typename: 'DataValue',
          },
          {
            name: '1_11',
            value: Math.random() * 12,
            __typename: 'DataValue',
          },
          {
            name: '4_11',
            value: Math.random() * 12,
            __typename: 'DataValue',
          },
          {
            name: '1_12',
            value: Math.random() * 12,
            __typename: 'DataValue',
          },
          {
            name: '4_12',
            value: Math.random() * 12,
            __typename: 'DataValue',
          },
          {
            name: '1_13',
            value: Math.random() * 12,
            __typename: 'DataValue',
          },
          {
            name: '4_13',
            value: Math.random() * 12,
            __typename: 'DataValue',
          },
          {
            name: '1_14',
            value: Math.random() * 12,
            __typename: 'DataValue',
          },
          {
            name: '4_14',
            value: Math.random() * 12,
            __typename: 'DataValue',
          },
          {
            name: '1_15',
            value: Math.random() * 12,
            __typename: 'DataValue',
          },
          {
            name: '4_15',
            value: Math.random() * 12,
            __typename: 'DataValue',
          },
          {
            name: '1_16',
            value: Math.random() * 12,
            __typename: 'DataValue',
          },
          {
            name: '4_16',
            value: Math.random() * 12,
            __typename: 'DataValue',
          },
          {
            name: '1_17',
            value: Math.random() * 12,
            __typename: 'DataValue',
          },
          {
            name: '4_17',
            value: Math.random() * 12,
            __typename: 'DataValue',
          },
          {
            name: '1_18',
            value: Math.random() * 12,
            __typename: 'DataValue',
          },
          {
            name: '4_18',
            value: Math.random() * 12,
            __typename: 'DataValue',
          },
          {
            name: '1_19',
            value: Math.random() * 12,
            __typename: 'DataValue',
          },
          {
            name: '4_19',
            value: Math.random() * 12,
            __typename: 'DataValue',
          },
          {
            name: '1_20',
            value: Math.random() * 12,
            __typename: 'DataValue',
          },
          {
            name: '4_20',
            value: Math.random() * 12,
            __typename: 'DataValue',
          },
          {
            name: '1_21',
            value: Math.random() * 12,
            __typename: 'DataValue',
          },
          {
            name: '4_21',
            value: Math.random() * 12,
            __typename: 'DataValue',
          },
          {
            name: '2_06',
            value: Math.random() * 12,
            __typename: 'DataValue',
          },
          {
            name: '2_07',
            value: Math.random() * 12,
            __typename: 'DataValue',
          },
          {
            name: '2_08',
            value: Math.random() * 12,
            __typename: 'DataValue',
          },
          {
            name: '2_09',
            value: Math.random() * 12,
            __typename: 'DataValue',
          },
          {
            name: '2_10',
            value: Math.random() * 12,
            __typename: 'DataValue',
          },
          {
            name: '2_11',
            value: Math.random() * 12,
            __typename: 'DataValue',
          },
          {
            name: '2_12',
            value: Math.random() * 12,
            __typename: 'DataValue',
          },
          {
            name: '2_13',
            value: Math.random() * 12,
            __typename: 'DataValue',
          },
          {
            name: '2_14',
            value: Math.random() * 12,
            __typename: 'DataValue',
          },
          {
            name: '2_15',
            value: Math.random() * 12,
            __typename: 'DataValue',
          },
          {
            name: '2_16',
            value: Math.random() * 12,
            __typename: 'DataValue',
          },
          {
            name: '2_17',
            value: Math.random() * 12,
            __typename: 'DataValue',
          },
          {
            name: '2_18',
            value: Math.random() * 12,
            __typename: 'DataValue',
          },
          {
            name: '2_19',
            value: Math.random() * 12,
            __typename: 'DataValue',
          },
          {
            name: '2_20',
            value: Math.random() * 12,
            __typename: 'DataValue',
          },
          {
            name: '2_21',
            value: Math.random() * 12,
            __typename: 'DataValue',
          },
          {
            name: '3_07',
            value: Math.random() * 12,
            __typename: 'DataValue',
          },
          {
            name: '3_08',
            value: Math.random() * 12,
            __typename: 'DataValue',
          },
          {
            name: '3_09',
            value: Math.random() * 12,
            __typename: 'DataValue',
          },
          {
            name: '3_10',
            value: Math.random() * 12,
            __typename: 'DataValue',
          },
          {
            name: '3_11',
            value: Math.random() * 12,
            __typename: 'DataValue',
          },
          {
            name: '3_12',
            value: Math.random() * 12,
            __typename: 'DataValue',
          },
          {
            name: '3_13',
            value: Math.random() * 12,
            __typename: 'DataValue',
          },
          {
            name: '3_14',
            value: Math.random() * 12,
            __typename: 'DataValue',
          },
          {
            name: '3_15',
            value: Math.random() * 12,
            __typename: 'DataValue',
          },
          {
            name: '3_16',
            value: Math.random() * 12,
            __typename: 'DataValue',
          },
          {
            name: '3_17',
            value: Math.random() * 12,
            __typename: 'DataValue',
          },
          {
            name: '3_18',
            value: Math.random() * 12,
            __typename: 'DataValue',
          },
          {
            name: '5_07',
            value: Math.random() * 12,
            __typename: 'DataValue',
          },
          {
            name: '5_08',
            value: Math.random() * 12,
            __typename: 'DataValue',
          },
          {
            name: '5_09',
            value: Math.random() * 12,
            __typename: 'DataValue',
          },
          {
            name: '5_10',
            value: Math.random() * 12,
            __typename: 'DataValue',
          },
          {
            name: '5_11',
            value: Math.random() * 12,
            __typename: 'DataValue',
          },
          {
            name: '5_12',
            value: Math.random() * 12,
            __typename: 'DataValue',
          },
        ],
        __typename: 'heartbeatChartHeatmapGridType',
      },
    },
  },
  [MeasureSlug.Margin]: {
    data: {
      heartbeatChartHeatmapGridV2: {
        data: [
          {
            name: '1_08',
            value: Math.random() * 23,
            __typename: 'DataValue',
          },
          {
            name: '4_08',
            value: Math.random() * 23,
            __typename: 'DataValue',
          },
          {
            name: '1_09',
            value: Math.random() * 23,
            __typename: 'DataValue',
          },
          {
            name: '4_09',
            value: Math.random() * 23,
            __typename: 'DataValue',
          },
          {
            name: '1_10',
            value: Math.random() * 23,
            __typename: 'DataValue',
          },
          {
            name: '4_10',
            value: Math.random() * 23,
            __typename: 'DataValue',
          },
          {
            name: '1_11',
            value: Math.random() * 23,
            __typename: 'DataValue',
          },
          {
            name: '4_11',
            value: Math.random() * 23,
            __typename: 'DataValue',
          },
          {
            name: '1_12',
            value: Math.random() * 23,
            __typename: 'DataValue',
          },
          {
            name: '4_12',
            value: Math.random() * 23,
            __typename: 'DataValue',
          },
          {
            name: '1_13',
            value: Math.random() * 23,
            __typename: 'DataValue',
          },
          {
            name: '4_13',
            value: Math.random() * 23,
            __typename: 'DataValue',
          },
          {
            name: '1_14',
            value: Math.random() * 23,
            __typename: 'DataValue',
          },
          {
            name: '4_14',
            value: Math.random() * 23,
            __typename: 'DataValue',
          },
          {
            name: '1_15',
            value: Math.random() * 23,
            __typename: 'DataValue',
          },
          {
            name: '4_15',
            value: Math.random() * 23,
            __typename: 'DataValue',
          },
          {
            name: '1_16',
            value: Math.random() * 23,
            __typename: 'DataValue',
          },
          {
            name: '4_16',
            value: Math.random() * 23,
            __typename: 'DataValue',
          },
          {
            name: '1_17',
            value: Math.random() * 23,
            __typename: 'DataValue',
          },
          {
            name: '4_17',
            value: Math.random() * 23,
            __typename: 'DataValue',
          },
          {
            name: '1_18',
            value: Math.random() * 23,
            __typename: 'DataValue',
          },
          {
            name: '4_18',
            value: Math.random() * 23,
            __typename: 'DataValue',
          },
          {
            name: '1_19',
            value: Math.random() * 23,
            __typename: 'DataValue',
          },
          {
            name: '4_19',
            value: Math.random() * 23,
            __typename: 'DataValue',
          },
          {
            name: '1_20',
            value: Math.random() * 23,
            __typename: 'DataValue',
          },
          {
            name: '4_20',
            value: Math.random() * 23,
            __typename: 'DataValue',
          },
          {
            name: '1_21',
            value: Math.random() * 23,
            __typename: 'DataValue',
          },
          {
            name: '4_21',
            value: Math.random() * 23,
            __typename: 'DataValue',
          },
          {
            name: '2_06',
            value: Math.random() * 23,
            __typename: 'DataValue',
          },
          {
            name: '2_07',
            value: Math.random() * 23,
            __typename: 'DataValue',
          },
          {
            name: '2_08',
            value: Math.random() * 23,
            __typename: 'DataValue',
          },
          {
            name: '2_09',
            value: Math.random() * 23,
            __typename: 'DataValue',
          },
          {
            name: '2_10',
            value: Math.random() * 23,
            __typename: 'DataValue',
          },
          {
            name: '2_11',
            value: Math.random() * 23,
            __typename: 'DataValue',
          },
          {
            name: '2_12',
            value: Math.random() * 23,
            __typename: 'DataValue',
          },
          {
            name: '2_13',
            value: Math.random() * 23,
            __typename: 'DataValue',
          },
          {
            name: '2_14',
            value: Math.random() * 23,
            __typename: 'DataValue',
          },
          {
            name: '2_15',
            value: Math.random() * 23,
            __typename: 'DataValue',
          },
          {
            name: '2_16',
            value: Math.random() * 23,
            __typename: 'DataValue',
          },
          {
            name: '2_17',
            value: Math.random() * 23,
            __typename: 'DataValue',
          },
          {
            name: '2_18',
            value: Math.random() * 23,
            __typename: 'DataValue',
          },
          {
            name: '2_19',
            value: Math.random() * 23,
            __typename: 'DataValue',
          },
          {
            name: '2_20',
            value: Math.random() * 23,
            __typename: 'DataValue',
          },
          {
            name: '2_21',
            value: Math.random() * 23,
            __typename: 'DataValue',
          },
          {
            name: '3_07',
            value: Math.random() * 23,
            __typename: 'DataValue',
          },
          {
            name: '3_08',
            value: Math.random() * 23,
            __typename: 'DataValue',
          },
          {
            name: '3_09',
            value: Math.random() * 23,
            __typename: 'DataValue',
          },
          {
            name: '3_10',
            value: Math.random() * 23,
            __typename: 'DataValue',
          },
          {
            name: '3_11',
            value: Math.random() * 23,
            __typename: 'DataValue',
          },
          {
            name: '3_12',
            value: Math.random() * 23,
            __typename: 'DataValue',
          },
          {
            name: '3_13',
            value: Math.random() * 23,
            __typename: 'DataValue',
          },
          {
            name: '3_14',
            value: Math.random() * 23,
            __typename: 'DataValue',
          },
          {
            name: '3_15',
            value: Math.random() * 23,
            __typename: 'DataValue',
          },
          {
            name: '3_16',
            value: Math.random() * 23,
            __typename: 'DataValue',
          },
          {
            name: '3_17',
            value: Math.random() * 23,
            __typename: 'DataValue',
          },
          {
            name: '3_18',
            value: Math.random() * 23,
            __typename: 'DataValue',
          },
          {
            name: '5_07',
            value: Math.random() * 23,
            __typename: 'DataValue',
          },
          {
            name: '5_08',
            value: Math.random() * 23,
            __typename: 'DataValue',
          },
          {
            name: '5_09',
            value: Math.random() * 23,
            __typename: 'DataValue',
          },
          {
            name: '5_10',
            value: Math.random() * 23,
            __typename: 'DataValue',
          },
          {
            name: '5_11',
            value: Math.random() * 23,
            __typename: 'DataValue',
          },
          {
            name: '5_12',
            value: Math.random() * 23,
            __typename: 'DataValue',
          },
        ],
        __typename: 'heartbeatChartHeatmapGridType',
      },
    },
  },
  [MeasureSlug.PromotionalMixOfSales]: {
    data: {
      heartbeatChartHeatmapGridV2: {
        data: [
          {
            name: '1_08',
            value: Math.random() * 58,
            __typename: 'DataValue',
          },
          {
            name: '4_08',
            value: Math.random() * 58,
            __typename: 'DataValue',
          },
          {
            name: '1_09',
            value: Math.random() * 58,
            __typename: 'DataValue',
          },
          {
            name: '4_09',
            value: Math.random() * 58,
            __typename: 'DataValue',
          },
          {
            name: '1_10',
            value: Math.random() * 58,
            __typename: 'DataValue',
          },
          {
            name: '4_10',
            value: Math.random() * 58,
            __typename: 'DataValue',
          },
          {
            name: '1_11',
            value: Math.random() * 58,
            __typename: 'DataValue',
          },
          {
            name: '4_11',
            value: Math.random() * 58,
            __typename: 'DataValue',
          },
          {
            name: '1_12',
            value: Math.random() * 58,
            __typename: 'DataValue',
          },
          {
            name: '4_12',
            value: Math.random() * 58,
            __typename: 'DataValue',
          },
          {
            name: '1_13',
            value: Math.random() * 58,
            __typename: 'DataValue',
          },
          {
            name: '4_13',
            value: Math.random() * 58,
            __typename: 'DataValue',
          },
          {
            name: '1_14',
            value: Math.random() * 58,
            __typename: 'DataValue',
          },
          {
            name: '4_14',
            value: Math.random() * 58,
            __typename: 'DataValue',
          },
          {
            name: '1_15',
            value: Math.random() * 58,
            __typename: 'DataValue',
          },
          {
            name: '4_15',
            value: Math.random() * 58,
            __typename: 'DataValue',
          },
          {
            name: '1_16',
            value: Math.random() * 58,
            __typename: 'DataValue',
          },
          {
            name: '4_16',
            value: Math.random() * 58,
            __typename: 'DataValue',
          },
          {
            name: '1_17',
            value: Math.random() * 58,
            __typename: 'DataValue',
          },
          {
            name: '4_17',
            value: Math.random() * 58,
            __typename: 'DataValue',
          },
          {
            name: '1_18',
            value: Math.random() * 58,
            __typename: 'DataValue',
          },
          {
            name: '4_18',
            value: Math.random() * 58,
            __typename: 'DataValue',
          },
          {
            name: '1_19',
            value: Math.random() * 58,
            __typename: 'DataValue',
          },
          {
            name: '4_19',
            value: Math.random() * 58,
            __typename: 'DataValue',
          },
          {
            name: '1_20',
            value: Math.random() * 58,
            __typename: 'DataValue',
          },
          {
            name: '4_20',
            value: Math.random() * 58,
            __typename: 'DataValue',
          },
          {
            name: '1_21',
            value: Math.random() * 58,
            __typename: 'DataValue',
          },
          {
            name: '4_21',
            value: Math.random() * 58,
            __typename: 'DataValue',
          },
          {
            name: '2_06',
            value: Math.random() * 58,
            __typename: 'DataValue',
          },
          {
            name: '2_07',
            value: Math.random() * 58,
            __typename: 'DataValue',
          },
          {
            name: '2_08',
            value: Math.random() * 58,
            __typename: 'DataValue',
          },
          {
            name: '2_09',
            value: Math.random() * 58,
            __typename: 'DataValue',
          },
          {
            name: '2_10',
            value: Math.random() * 58,
            __typename: 'DataValue',
          },
          {
            name: '2_11',
            value: Math.random() * 58,
            __typename: 'DataValue',
          },
          {
            name: '2_12',
            value: Math.random() * 58,
            __typename: 'DataValue',
          },
          {
            name: '2_13',
            value: Math.random() * 58,
            __typename: 'DataValue',
          },
          {
            name: '2_14',
            value: Math.random() * 58,
            __typename: 'DataValue',
          },
          {
            name: '2_15',
            value: Math.random() * 58,
            __typename: 'DataValue',
          },
          {
            name: '2_16',
            value: Math.random() * 58,
            __typename: 'DataValue',
          },
          {
            name: '2_17',
            value: Math.random() * 58,
            __typename: 'DataValue',
          },
          {
            name: '2_18',
            value: Math.random() * 58,
            __typename: 'DataValue',
          },
          {
            name: '2_19',
            value: Math.random() * 58,
            __typename: 'DataValue',
          },
          {
            name: '2_20',
            value: Math.random() * 58,
            __typename: 'DataValue',
          },
          {
            name: '2_21',
            value: Math.random() * 58,
            __typename: 'DataValue',
          },
          {
            name: '3_07',
            value: Math.random() * 58,
            __typename: 'DataValue',
          },
          {
            name: '3_08',
            value: Math.random() * 58,
            __typename: 'DataValue',
          },
          {
            name: '3_09',
            value: Math.random() * 58,
            __typename: 'DataValue',
          },
          {
            name: '3_10',
            value: Math.random() * 58,
            __typename: 'DataValue',
          },
          {
            name: '3_11',
            value: Math.random() * 58,
            __typename: 'DataValue',
          },
          {
            name: '3_12',
            value: Math.random() * 58,
            __typename: 'DataValue',
          },
          {
            name: '3_13',
            value: Math.random() * 58,
            __typename: 'DataValue',
          },
          {
            name: '3_14',
            value: Math.random() * 58,
            __typename: 'DataValue',
          },
          {
            name: '3_15',
            value: Math.random() * 58,
            __typename: 'DataValue',
          },
          {
            name: '3_16',
            value: Math.random() * 58,
            __typename: 'DataValue',
          },
          {
            name: '3_17',
            value: Math.random() * 58,
            __typename: 'DataValue',
          },
          {
            name: '3_18',
            value: Math.random() * 58,
            __typename: 'DataValue',
          },
          {
            name: '5_07',
            value: Math.random() * 58,
            __typename: 'DataValue',
          },
          {
            name: '5_08',
            value: Math.random() * 58,
            __typename: 'DataValue',
          },
          {
            name: '5_09',
            value: Math.random() * 58,
            __typename: 'DataValue',
          },
          {
            name: '5_10',
            value: Math.random() * 58,
            __typename: 'DataValue',
          },
          {
            name: '5_11',
            value: Math.random() * 58,
            __typename: 'DataValue',
          },
          {
            name: '5_12',
            value: Math.random() * 58,
            __typename: 'DataValue',
          },
        ],
        __typename: 'heartbeatChartHeatmapGridType',
      },
    },
  },
};
