import { IDataValue } from '@tapestry/shared/graphql';
import dayjs from 'dayjs';

export const makeMockDataValues = (
  from: string,
  to: string,
  mockValueFunc: () => number
) => {
  const placeholderPeriodArray: IDataValue[] = [];
  const startDate = dayjs(from);
  const diffNumber = dayjs(to).diff(startDate, 'day');

  for (let idx = 0; idx <= diffNumber; idx++) {
    const dayData = {
      name: startDate.add(idx, 'day').format('YYYY-MM-DD'),
      value: mockValueFunc(),
    };

    placeholderPeriodArray.push(dayData);
  }

  return placeholderPeriodArray;
};
