import { Operation } from '@apollo/client';
import { MOCK_SCOPE_TYPES } from '../mock-subject';
import { isDummyShopOrGroup } from './dummy-shops';

/**
 * Logic to figure out if it needs mocking or not
 */
export const requiresMocking = (variables: Operation['variables']) => {
  const { scopeType } = variables;

  if (MOCK_SCOPE_TYPES.includes(scopeType) || isDummyShopOrGroup(variables)) {
    return true;
  }

  return false;
};
