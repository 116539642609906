import { randomIntegerBetween } from '@tapestry/shared/utils';

export const MOCK_HEARTBEAT_SUMMARIES = {
  data: {
    heartbeatSummaries: {
      metrics: [
        {
          name: 'total_sales',
          value: 281840.2,
          __typename: 'DataValue',
        },
        {
          name: 'transactions',
          value: 5543,
          __typename: 'DataValue',
        },
        {
          name: 'profitability',
          value: 67853.9325,
          __typename: 'DataValue',
        },
        {
          name: 'margin',
          value: 24.0753,
          __typename: 'DataValue',
        },
        {
          name: 'basket_value',
          value: 50.8485,
          __typename: 'DataValue',
        },
        {
          name: 'basket_margin',
          value: 24.0764,
          __typename: 'DataValue',
        },
        {
          name: 'basket_profit',
          value: 12.2447,
          __typename: 'DataValue',
        },
        {
          name: 'on_promotion',
          value: 137204.99,
          __typename: 'DataValue',
        },
        {
          name: 'promotional_mix_of_sales',
          value: 48.6818,
          __typename: 'DataValue',
        },
        {
          name: 'sales_per_sq',
          value: 187.8935,
          __typename: 'DataValue',
        },
        {
          name: 'sales_per_labour',
          value: randomIntegerBetween(7 * 69, 7 * 175),
          __typename: 'DataValue',
        },
        {
          name: 'total_wage_cost',
          value: randomIntegerBetween(7 * 10989, 7 * 23463),
          __typename: 'DataValue',
        },
        {
          name: 'total_wage_hours',
          value: randomIntegerBetween(7 * 333, 7 * 711),
          __typename: 'DataValue',
        },
      ],
      __typename: 'heartbeatSummariesType',
    },
  },
};
