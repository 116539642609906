/**
 * This file list all the mock subjects => when should data get mocked
 */
import { MeasureSlug, THREAD_TYPE } from '@tapestry/types';

/**
 * Thread types / dimensions
 */
export const MOCK_SCOPE_TYPES = [
  THREAD_TYPE.DEMOGRAPHIC,
  THREAD_TYPE.POSTCODE,
  THREAD_TYPE.STAFF,
];

/**
 * Decide what need to be mocked in the dummy shops
 */
export const DUMMY_SHOPS_MOCK_MEASURES = [
  MeasureSlug.SalesPerLabourHour,
  MeasureSlug.TotalWageHours,
  MeasureSlug.TotalWageCost,
];
