import { Operation } from '@apollo/client';
import { DEMO_SHOPS_IDS } from '@tapestry/shared/constants';
import { MeasureSlug } from '@tapestry/types';
import { DUMMY_SHOPS_MOCK_MEASURES } from '../mock-subject';

export const shouldMockMeasureForDummyShop = (measure: MeasureSlug) => {
  return DUMMY_SHOPS_MOCK_MEASURES.includes(measure);
};

export const isDummyShopOrGroup = (variables: Operation['variables']) => {
  const { filters } = variables;
  const isMockedShopOrGroup =
    DEMO_SHOPS_IDS.includes(filters?.shopId) ||
    DEMO_SHOPS_IDS.includes(filters?.groupId);

  return isMockedShopOrGroup;
};
